import React, {memo} from 'react';
import Loader from '../../components/Loader';
import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import useUsers from './users-hook';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ReplayIcon from '@mui/icons-material/Replay';
import UserForm from "../../components/UsersForm";

const Users = () => {
  const {
    users,
    propertyUsers,
    loading,
    handleClickOpen,
    handleClose,
    open,
    refetch,
    onDeclineUser,
    handleCloseDeclineModal,
    handleClickOpenDeclineModal,
    openDeclineModal,
    departments,
    onClose,
    selectedUser
  } = useUsers();

  const columns: GridColDef[] = [
    {
      field: 'first_name',
      renderHeader: () => <strong>{'Full Name'}</strong>,
      width: 200,
      editable: false,
      renderCell: (params) =>
        `${params.row.first_name} ${params.row.last_name}`,
    },
    {
      field: 'email',
      renderHeader: () => <strong>{'Email'}</strong>,
      width: 250,
      editable: false,
    },
    {
      field: 'employee_id',
      renderHeader: () => <strong>{'Employee ID'}</strong>,
      width: 250,
      editable: false,
    },
    {
      field: 'created_at',
      renderHeader: () => <strong>{'Creation Date'}</strong>,
      width: 200,
      editable: false,
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      field: 'status',
      renderHeader: () => <strong>{'Action'}</strong>,
      width: 270,
      renderCell: (params) => (
        <Stack direction="row" spacing={2}>
          <Button
            onClick={() => handleClickOpen(params.row.id)}
            variant="outlined"
            color="success"
            size="small"
            startIcon={<CheckIcon />}
          >
            Authorize
          </Button>
          <Button
            variant="outlined"
            color="error"
            size="small"
            startIcon={<ClearIcon />}
            onClick={() => handleClickOpenDeclineModal(params.row.id)}
          >
            Decline
          </Button>
        </Stack>
      ),
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <Button
          startIcon={<ReplayIcon />}
          onClick={() => {
            refetch();
          }}
        >
          Refresh
        </Button>
        <GridToolbarQuickFilter />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ height: '700px', width: '100%' }}>
              <DataGrid
                rows={users || []}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[15]}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                loading={loading}
                components={{ Toolbar: CustomToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </div>
            <Dialog
              maxWidth="sm"
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle>Authorize User</DialogTitle>
              <UserForm
                departments={departments || []}
                handleClose={handleClose}
                propertyUsers={propertyUsers || []}
                onClose={onClose}
                selectedUser={selectedUser}
              />
            </Dialog>
            <Dialog
              maxWidth="sm"
              open={openDeclineModal}
              onClose={handleCloseDeclineModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle>Decline User</DialogTitle>
              <DialogContent>
                <DialogContentText>Are you sure?</DialogContentText>
                <DialogContentText>
                  This action is not reversible, User will have to re-register!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDeclineModal}>Cancel</Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onDeclineUser}
                >
                  Decline
                </Button>
              </DialogActions>
            </Dialog>
          </LocalizationProvider>
        </>
      )}
    </>
  );
};

export default memo(Users);
