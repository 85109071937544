import React, { memo } from 'react';
import Loader from '../../components/Loader';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Paper,
  Chip,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Menu,
  TableRow, List, ListItem, ListItemText, Link, ListItemIcon,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import useChallenge from './challenge-hook';
import _ from 'lodash';
import ChallengeForm from "./ChallengeForm";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";

type PropsRow = {
  id: string;
  department_id: string;
  description: string;
  points: number;
  name: string;
  status: string;
  sub_challenges: Array<string>;
  doc_urls: Array<string>;
};
/* eslint-disable no-unused-vars */
const Row = (props: {
  row: PropsRow,
  handleClickChallengeEdit: (id: string) => void }) => {
  const {
    handleClickSubChallengeOpen,
    registerSubChallenge,
    openSubChallenge,
    handleSubmitSubChallenge,
    onSubmitSubChallenge,
    handleSubChallengeClose,
    challengeName,
    handleClickChallengeDelete,
    handleClickSubChallengeDelete,
    handleUpdateStatus,
    departmentMap,
  } = useChallenge();
  const { row, handleClickChallengeEdit } = props;

  const [open, setOpen] = React.useState(false);
  //Challenge Menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openChallengeAction = Boolean(anchorEl);
  const handleChallengeClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChallengeClose = () => {
    setAnchorEl(null);
  };
  // Sub Challenge Menu
  const [anchorE2, setAnchorE2] = React.useState<null | HTMLElement>(null);
  const openSubChallengeAction = Boolean(anchorE2);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE2(null);
  };
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="left">
          <Chip
            label={row.status}
            color={row.status === 'INACTIVE' ? 'error' : 'success'}
          ></Chip>
        </TableCell>
        <TableCell align="left">
          {_.get(departmentMap, row.department_id)?.name}
        </TableCell>
        <TableCell align="left">{row.points}</TableCell>
        <TableCell align="left">
          <List>
            {row.doc_urls?.map((url, index) =>
              <ListItem>
                <ListItemIcon>
                  {
                    url?.endsWith("pdf") ?
                      <PictureAsPdfIcon/> :
                      <ImageIcon/>
                  }
                </ListItemIcon>
                <Link href={url} color="inherit" target="_blank">
                  <ListItemText
                    primary={`File ${index+1}`}
                  />
                </Link>
              </ListItem>
            )}
          </List>
        </TableCell>
        <TableCell align="left">
          <IconButton
            id="challenge-button"
            aria-controls={openChallengeAction ? 'challenge-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openChallengeAction ? 'true' : undefined}
            onClick={handleChallengeClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="challenge-menu"
            anchorEl={anchorEl}
            open={openChallengeAction}
            onClose={handleChallengeClose}
            MenuListProps={{
              'aria-labelledby': 'challenge-button',
            }}
          >
            <MenuItem
              onClick={() => handleClickSubChallengeOpen(row.id,row.name)}
            >
              Add Sub Challenge
            </MenuItem>

            {row.status === 'INACTIVE' ? (
              <MenuItem onClick={() => handleUpdateStatus(row.id)}>
                Activate
              </MenuItem>
            ) : (
              ''
            )}
            <MenuItem onClick={() => handleClickChallengeEdit(row.id)}>
              Edit
            </MenuItem>
            <MenuItem onClick={() => handleClickChallengeDelete(row.id)}>
              Delete
            </MenuItem>
          </Menu>
          <Dialog open={openSubChallenge} onClose={handleSubChallengeClose}>
            <form onSubmit={handleSubmitSubChallenge(onSubmitSubChallenge)}>
              <DialogTitle>Add Sub Challenge</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please enter your challenge details. We will send updates
                  occasionally.
                </DialogContentText>
                <TextField
                  margin="normal"
                  fullWidth
                  id="title"
                  label="Title"
                  {...registerSubChallenge('title', { required: true })}
                  autoComplete="title"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  fullWidth
                  id="challengeName"
                  label="ChallengeName"
                  disabled
                  autoComplete="challenge_id"
                  defaultValue={challengeName}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  label="Points"
                  type="text"
                  id="points"
                  {...registerSubChallenge('points', { required: true })}
                  autoComplete="current-password"
                />
                <TextField
                  margin="normal"
                  fullWidth
                  id="description"
                  label="Description"
                  {...registerSubChallenge('description', { required: true })}
                  autoComplete="description"
                  autoFocus
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleSubChallengeClose}>Cancel</Button>
                <Button color="primary" variant="contained" type="submit">
                  Add Sub Challenge
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Sub Challenges
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <TableCell />
                    <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                      Title
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                      Points
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                      Action
                    </TableCell>
                  </TableRow>
                  {row?.sub_challenges?.map((sub: any) => (
                    <TableRow key="1">
                      <TableCell />
                      <TableCell align="left" scope="row">{sub.name}</TableCell>
                      <TableCell align="left">{sub.points}</TableCell>
                      <TableCell align="left">
                        <IconButton
                          id="subChallenge-button"
                          aria-controls={
                            openSubChallengeAction
                              ? 'subChallenge-menu'
                              : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={
                            openSubChallengeAction ? 'true' : undefined
                          }
                          onClick={handleClick}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="subChallenge-menu"
                          anchorEl={anchorE2}
                          open={openSubChallengeAction}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'subChallenge-button',
                          }}
                        >
                          <MenuItem
                            onClick={() =>
                              handleClickSubChallengeDelete(sub.id)
                            }
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const Challenge = () => {
  const {
    dataRows,
    loading,
    handleClickChallengeOpen,
    openChallenge,
    handleChallengeClose,
    handleClickChallengeEdit,
    onSubmit,
    registerChallenge,
    handleSubmitChallenge,
    deptData,
    challengeRewardType,
    handleUpload,
    challengeTermsAndConditions,
    removeTermsFile,
    loader,
  } = useChallenge();
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper}>
          <Button
            color="primary"
            variant="contained"
            sx={{ m: 2, float: 'right' }}
            onClick={handleClickChallengeOpen}
          >
            <AddIcon />
            Add Challenge
          </Button>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                  Challenge Title
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                  Status
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                  Department
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                  Points
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                  T&C
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataRows?.map((row: any) => (
                <Row
                  key={row.id}
                  row={row}
                  handleClickChallengeEdit={handleClickChallengeEdit}
                />
              ))}
            </TableBody>
          </Table>
          <ChallengeForm
            openChallenge={openChallenge}
            handleChallengeClose={handleChallengeClose}
            onSubmit={onSubmit}
            registerChallenge={registerChallenge}
            handleSubmitChallenge={handleSubmitChallenge}
            deptData={deptData}
            challengeRewardType={challengeRewardType}
            handleUpload={handleUpload}
            challengeTermsAndConditions={challengeTermsAndConditions}
            removeTermsFile={removeTermsFile}
            loader={loader}
          />
        </TableContainer>
      )}
    </>
  );
};
export default memo(Challenge);
