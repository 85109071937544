import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport,
  GridToolbarFilterButton, GridToolbarQuickFilter
} from "@mui/x-data-grid";
import useReports from "./reports-hook";
import {Button, Dialog, Stack, TextField} from "@mui/material";
import ReplayIcon from '@mui/icons-material/Replay';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import DownloadIcon from '@mui/icons-material/Download';
import React from "react";
import DocumentList from "../../components/DocumentList";

const Reports = () => {
  const {
    loading,
    dateRange,
    entries,
    changeDateRange,
    refetch,
    saveZip
  } = useReports();

  const [image , setImage] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (image:string) => {
    if (image.endsWith(".pdf")) {
      const pdfWindow = window.open();
      if (pdfWindow && pdfWindow.location) {
        pdfWindow.location.href = image;
      }
    } else {
      setImage(image);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'employee_name',
      headerName: 'Employee Name',
      width: 150,
      editable: false,
    },
    {
      field: 'challenge_name',
      headerName: 'Challenge',
      width: 200,
      editable: false,
    },
    {
      field: 'challenge_terms',
      headerName: 'Challenge Terms',
      width: 150,
      editable: false,
      renderCell: (params) => <DocumentList image_url={params.value} handleClickOpen={handleClickOpen} />
    },
    {
      field: 'reference_receipt',
      headerName: 'Receipt Number',
      width: 200,
      editable: false,
    },
    {
      field: 'created_at',
      headerName: 'Submission Date',
      width: 200,
      editable: false,
      valueFormatter: (params) => new Date(params.value).toLocaleDateString()
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      editable: false,
    },
    {
      field: 'approved_by',
      headerName: 'Approved By',
      width: 200,
      editable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      sortable: true,
      width: 100,
    },
    {
      field: 'submitted_documents',
      headerName: 'Submitted Documents',
      width: 200,
      editable: false,
      renderCell: (params) => <DocumentList image_url={params.value} handleClickOpen={handleClickOpen} />
    },
    {
      field: 'approved_points',
      headerName: 'Received Points',
      sortable: true,
      width: 150,
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <Button startIcon={<ReplayIcon />} onClick={()=> {
          refetch();
        }}>
          Refresh
        </Button>
        <GridToolbarQuickFilter/>
        <GridToolbarExport/>
        <Button startIcon={<DownloadIcon />} onClick={()=> {
          saveZip();
        }}>
          Download Files
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ height: 650, width: '100%' }}>
        <Stack direction="row" spacing={2} sx={{m:4}}>
          <DatePicker
            label="Start Date"
            value={dateRange.startDate}
            onChange={
              (newValue) =>
                changeDateRange(
                  newValue || dateRange.startDate,
                  dateRange.endDate
                )
            }
            renderInput={(params) => <TextField {...params}/>}
          />
          <DatePicker
            label="End Date"
            value={dateRange.endDate}
            onChange={
              (newValue) =>
                changeDateRange(
                  dateRange.startDate,
                  newValue || dateRange.endDate
                )
            }
            renderInput={(params) => <TextField {...params}/>}
          />
        </Stack>
        <DataGrid
          rows={entries}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          loading={loading}
          components={{ Toolbar: CustomToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <img
          style={{ width: '400px', height: '300px' }}
          src={image}
          alt="points"
        />
      </Dialog>
    </LocalizationProvider>
  );
};

export default Reports;
