export const USERS = `/users`;
export const POINTS_REQUEST = `/convertPoints`;
export const APPROVAL = `/approval`;
export const FAVOURITE = `/favourite`;
export const ANALYTICS = `/analytics`;
export const SETTINGS = `/settings`;
export const CHALLENGE = `/challenge`;
export const POINTS = `/points`;
export const REPORTS_REQUEST = `/reports`;
export const DEPARTMENTS = `/departments`;
