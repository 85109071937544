import React, {memo} from 'react';
import Loader from '../../components/Loader';
import {
  Paper,
  TableContainer,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useUserList from './userlist-hook';
import {
  GridColDef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
  GridToolbarExport,
  DataGrid,
} from '@mui/x-data-grid';
import ReplayIcon from '@mui/icons-material/Replay';
import UserForm from "../../components/UsersForm";

const UserList = () => {
  const {
    users,
    deletedUsers,
    loading,
    handleClickOpen,
    handleClose,
    open,
    onDeleteUser,
    departments,
    refetch,
    selectedUser,
    onClose,
    departmentsMap
  } = useUserList();

  const columns: GridColDef[] = [
    {
      field: 'first_name',
      renderHeader: () => <strong>{'Full Name'}</strong>,
      width: 200,
      editable: false,
      renderCell: (params) =>
        `${params.row.first_name} ${params.row.last_name}`,
    },
    {
      field: 'email',
      renderHeader: () => <strong>{'Email'}</strong>,
      width: 250,
      editable: false,
    },
    {
      field: 'employee_id',
      renderHeader: () => <strong>{'Employee ID'}</strong>,
      width: 250,
      editable: false,
    },
    {
      field: 'department_name',
      renderHeader: () => <strong>{'Department'}</strong>,
      width: 250,
      editable: false,
      renderCell: (params) => (
        <Typography>{params.value || ""}</Typography>
      ),
    },
    {
      field: 'total_points',
      renderHeader: () => <strong>{'Total Points'}</strong>,
      width: 200,
      editable: false,
    },
    {
      field: 'deleted_at',
      renderHeader: () => <strong>{'Status'}</strong>,
      width: 200,
      editable: false,
      renderCell: (params) => `${params.value ? 'Deleted' : 'Active'}`,
    },
    {
      field: 'is_authorized',
      renderHeader: () => <strong>{'Action'}</strong>,
      width: 270,
      renderCell: (params) => (
        <Stack direction="row" spacing={2}>
          <Button
            onClick={() => handleClickOpen(params.row)}
            variant="outlined"
            color="success"
            size="small"
            startIcon={<EditIcon />}
            disabled={!!params.row.deleted_at}
          >
            Edit
          </Button>
          {!params.row.deleted_at && (
            <DeleteIcon onClick={() => onDeleteUser(params.row.id)} />
          )}
        </Stack>
      ),
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <Button
          startIcon={<ReplayIcon />}
          onClick={() => {
            refetch();
          }}
        >
          Refresh
        </Button>
        <GridToolbarQuickFilter />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
        <TableContainer component={Paper} sx={{ height: '650px', p:2 }}>
          <DataGrid
            rows={
              users?.filter((user) => user?.is_authorized === true)?.map(
                user => {
                  return {
                    ...user,
                    department_name: 
                      departmentsMap[user?.department_id || ""]?.name || ""
                  };
                }) || []
            }
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[15]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            loading={loading}
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
          {open && (
            <Dialog
              maxWidth="sm"
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle>Update User</DialogTitle>
              <UserForm
                departments={departments || []}
                handleClose={handleClose}
                propertyUsers={users || []}
                onClose={onClose}
                selectedUser={selectedUser}
              />
            </Dialog>
          )}
        </TableContainer>
        <TableContainer component={Paper} sx={{ height: '650px', p:2 }}>
          <Typography variant='h6'>Archived Users</Typography>
          <DataGrid
            rows={deletedUsers?.map(user => {
              return {
                ...user,
                department_name: 
                  departmentsMap[user?.department_id || ""]?.name || ""
              };
            }) || []}
            columns={columns.slice(0, -1)}
            pageSize={10}
            rowsPerPageOptions={[15]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            loading={loading}
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </TableContainer>
        </>
        
      )}
    </>
  );
};

export default memo(UserList);
