import { Navigate, Routes } from 'react-router-dom';
import { getToken } from './auth';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

const PrivateRoute = ({ children }: Props) => {
  const token = getToken();
  return token ? <Routes>{children}</Routes> : <Navigate to={"/login"} />;
};

export default PrivateRoute;

