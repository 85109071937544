import React, { memo, useState } from 'react';
import Loader from '../../components/Loader';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Typography,
} from '@mui/material';
import usePointsSubmission from './points-hook';
import _ from 'lodash';
import SubmissionsDataGrid from './SubmissionsDataGrid';
import { PointsSubmission, PointsSubmissionStatus } from '../../generated/graphql';

const Points = () => {

  const [image , setImage]=useState("");
  const {
    pointSubmissionsList,
    handleClickOpenPointDialog,
    handleClosePointDialog,
    openPoint,
    register,
    handleSubmit,
    onSubmit,
    loading,
    usersMap,
    refetch,
    openedSubmission,
    cancelSubmission,
    handleConfirmDeline,
    handleCancelDeclineConfirmation,
    setCancelSubmission,
    declineRegister,
    handleDeclineSubmit,
    revertSubmission,
    setRevertSubmission,
    handleConfirmRevert,
    handleCancelRevertConfirmation
  } = usePointsSubmission();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (image:string) => {
    if (image.endsWith(".pdf")) {
      const pdfWindow = window.open();
      if (pdfWindow && pdfWindow.location) {
        pdfWindow.location.href = image;
      }
    } else {
      setImage(image);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getUserName = (userID: string) => {
    return `${_.get(usersMap,userID)?.first_name} ${_.get(usersMap,userID)?.last_name}`;
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
        <Typography variant='h6' sx={{p:2}}>Pending Submissions</Typography>
        <SubmissionsDataGrid
          loading={loading}
          entries={
            pointSubmissionsList?.filter(
              submission => submission?.status === PointsSubmissionStatus.Pending
            ).map((submission: PointsSubmission | null) => {
              return {
                id: submission?.id,
                employee_name: getUserName(submission?.user_id || "") || "",
                challenge_name: submission?.challenge_name || "",
                amount: submission?.amount,
                receipt_number: submission?.reference_receipt,
                submitted_documents: submission?.image_url,
                submission_date: submission?.created_at,
                status: submission?.status,
                submission: submission,
                challenge_terms: submission?.challenge_terms
              };
            }) || []
          }
          refetch={refetch}
          handleClickOpen={handleClickOpen}
          handleClickOpenPointDialog={handleClickOpenPointDialog}
          setCancelSubmission={setCancelSubmission}
          setRevertSubmission={setRevertSubmission}
        />
        <Typography variant='h6' sx={{p:2}}>Approved Submissions</Typography>
        <SubmissionsDataGrid
          loading={loading}
          entries={
            pointSubmissionsList?.filter(
              submission => submission?.status === PointsSubmissionStatus.Approved
            ).map((submission: PointsSubmission | null) => {
              return {
                id: submission?.id,
                employee_name: getUserName(submission?.user_id || "") || "",
                challenge_name: submission?.challenge_name || "",
                amount: submission?.amount,
                receipt_number: submission?.reference_receipt,
                submitted_documents: submission?.image_url,
                submission_date: submission?.created_at,
                status: submission?.status,
                submission: submission,
                challenge_terms: submission?.challenge_terms
              };
            }) || []
          } 
          refetch={refetch}
          handleClickOpen={handleClickOpen}
          handleClickOpenPointDialog={handleClickOpenPointDialog}
          setCancelSubmission={setCancelSubmission}
          setRevertSubmission={setRevertSubmission}
        />
        <Typography variant='h6' sx={{p:2}}>Declined Submissions</Typography>
        <SubmissionsDataGrid
          loading={loading}
          entries={
            pointSubmissionsList?.filter(
              submission => submission?.status === PointsSubmissionStatus.Declined
            ).map((submission: PointsSubmission | null) => {
              return {
                id: submission?.id,
                employee_name: getUserName(submission?.user_id || "") || "",
                challenge_name: submission?.challenge_name || "",
                amount: submission?.amount,
                receipt_number: submission?.reference_receipt,
                submitted_documents: submission?.image_url,
                submission_date: submission?.created_at,
                status: submission?.status,
                submission: submission,
                challenge_terms: submission?.challenge_terms
              };
            }) || []
          } 
          refetch={refetch}
          handleClickOpen={handleClickOpen}
          handleClickOpenPointDialog={handleClickOpenPointDialog}
          setCancelSubmission={setCancelSubmission}
          setRevertSubmission={setRevertSubmission}
        />
        <Dialog
          maxWidth="sm"
          open={openPoint}
          onClose={handleClosePointDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Approve Points</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {getUserName(openedSubmission?.user_id)}
              </DialogContentText>
              <DialogContentText>
                {openedSubmission?.challenge_name}
              </DialogContentText>
              <TextField
                margin="normal"
                fullWidth
                id="points"
                label="Points"
                {...register('approvedPoints', { required: true })}
                autoComplete="points"
                autoFocus
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosePointDialog}>Cancel</Button>
              <Button color="primary" variant="contained" type="submit">
                Submit
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <img
            style={{ width: '400px', height: '300px' }}
            src={image}
            alt="points"
          />
        </Dialog>
        <Dialog
          maxWidth="xs"
          open={cancelSubmission ? true : false}
        >
          <DialogTitle>Decline submission</DialogTitle>
          <form onSubmit={handleDeclineSubmit(handleConfirmDeline)}>
            <DialogContent dividers>
              Are you sure?
              <TextField
                margin="normal"
                fullWidth
                id="decline"
                label="Reason"
                {...declineRegister('reason', { required: true })}
                autoFocus
              />
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCancelDeclineConfirmation}>
                Cancel
              </Button>
              <Button type="submit" variant="contained">Decline</Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog
          maxWidth="xs"
          open={revertSubmission ? true : false}
        >
          <DialogTitle>Revert submission</DialogTitle>
            <DialogContent dividers>
              Are you sure?
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCancelRevertConfirmation}>
                Cancel
              </Button>
              <Button autoFocus onClick={handleConfirmRevert} variant="contained">
                Revert
              </Button>
            </DialogActions>
        </Dialog>
        {/* <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                  Challenge Name
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                  Sub Challenge
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                  Amount
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                  Receipt No
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                  Submitted Documents
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                  Date
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pointSubmissionsList?.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    { getUserName(row.user_id)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.challenge_name}
                  </TableCell>
                  <TableCell align="left">{row.amount}</TableCell>
                  <TableCell align="left">{row.reference_receipt}</TableCell>
                  <TableCell align="left">
                    {!Array.isArray(row.image_url) ? 
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={()=>handleClickOpen(row.image_url)}
                      >
                        <ImageIcon />
                      </IconButton>:
                    <Stack direction="row">
                      {
                        row.image_url.map((url: string)=>
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                              onClick={()=>handleClickOpen(url)}
                              key={url}
                            >
                              { url.endsWith(".pdf") ? <PictureAsPdfIcon /> : <ImageIcon /> }
                            </IconButton>
                        )
                      }
                    </Stack>
                    }
                    
                  </TableCell>
                  <TableCell align="left">
                  {new Date(row.created_at).toLocaleDateString()}
                  </TableCell>
                  {row.status === 'PENDING' ? (
                    <TableCell align="left">
                      <Stack direction="row" spacing={2}>
                        <Button
                          onClick={() => handleClickOpenPointDialog(row)}
                          variant="outlined"
                          color="success"
                          size="small"
                          startIcon={<CheckIcon />}
                        >
                          Approve
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          startIcon={<ClearIcon />}
                        >
                          Decline
                        </Button>
                      </Stack>
                    </TableCell>
                  ) : (
                    <TableCell align="left">Approved</TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
        </>
      )}
    </>
  );
};

export default memo(Points);
