import gql from 'graphql-tag';

const query = gql`
  query Me {
    me {
      id
      first_name
      last_name
      email
      phone
      profile_url
      property_id
      is_admin
    }
  }
`;

export default query;