/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useState, useContext } from 'react';
import { Cache } from 'aws-amplify';
import { User } from '../variables/types';

const AuthContext = createContext({});

const Context = (props: any) => {
  const [user, setNewUser] = useState<User | null>(Cache.getItem('user'));

  const value = React.useMemo(
    () => ({
      user,
      setNewUser,
    }),
    [user],
  );

  return <AuthContext.Provider value={value} {...props} />;
};

export const useAuthProvider = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(
      'useAuthProvider() has to be used within a child of the Auth Provider',
    );
  }

  const { setNewUser }: any = context;

  const getUser = () => Cache.getItem('user');

  const setUser = (newUser: any) => {
    setNewUser(newUser);
    Cache.setItem('user', newUser);
  };

  return {
    getUser,
    setUser,
  };
};

export default Context;