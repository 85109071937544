import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  Operation,
  ServerError,
} from '@apollo/client';
import { onError } from '@apollo/link-error';
import { deleteToken, getJWTToken } from './auth';
import * as Sentry from '@sentry/react';
import { setContext } from '@apollo/client/link/context';

const {
  REACT_APP_API_URL,
} = process.env;

const isPrivateRequest = (op: Operation) =>
  op.getContext().clientName !== 'PUBLIC';

const authContext = setContext(async () => {
  const token = await getJWTToken();
  return {
    headers: {
      authorization: token ? `${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      Sentry.captureMessage(
        `[GraphQL error]: Message: ${message},
         Location: ${locations}, Path: ${path}`,
      ),
    );
  }
  if ((networkError as ServerError)?.statusCode === 401) {
    deleteToken();
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authContext,
    ApolloLink.split(
      (op) => isPrivateRequest(op),
      new HttpLink({
        uri: `${REACT_APP_API_URL}`,
      }),
    ),
  ]),
  cache: new InMemoryCache(),
});

export default client;
