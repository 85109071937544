import noop from "../../utils/noop";
import { useState, useRef } from "react";
import {
  Noop,
  NotifierProps,
  NotifierType,
  OpenSnackbarFunction,
} from "../../variables/types";

export interface State extends NotifierProps {
  open: boolean;
  variant: NotifierType;
}

const useNotifier = () => {
  const openSnackbar = useRef<OpenSnackbarFunction | Noop>(noop);
  const [state, setState] = useState<State>({
    open: false,
    message: "",
    messageTitle: "",
    variant: NotifierType.Info,
    customIcon: null,
    actionButtonText: "",
    onActionButtonClick: noop,
  });

  openSnackbar.current = (
    {
      message,
      customIcon,
      actionButtonText,
      messageTitle,
      onActionButtonClick,
    }: NotifierProps,
    variant: NotifierType
  ) => {
    setState((prevState) => ({
      ...prevState,
      open: true,
      message,
      variant,
      customIcon,
      messageTitle,
      actionButtonText,
      onActionButtonClick,
    }));
  };

  const handleSnackbarClose = () => {
    setState((prevState) => ({
      ...prevState,
      open: false,
      message: "",
      variant: NotifierType.Info,
      customIcon: null,
      messageTitle: "",
      actionButtonText: "",
      onActionButtonClick: noop,
    }));
  };

  return {
    ...state,
    handleSnackbarClose,
    openSnackbar,
  };
};

export default useNotifier;
