import React, { memo } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Container,
  Box,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountPopover from './AccountPopover';

interface layoutProp {
  drawerWidth: Number;
  handleDrawerToggle: Function;
}
const Topbar = memo(({ drawerWidth, handleDrawerToggle }: layoutProp) => {
  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontWeight: 'bold',
            }}
          >
            Dashboard
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => handleDrawerToggle()}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            Dashboard
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}></Box>

          <Box sx={{ flexGrow: 0 }}>
            <Typography
              component="div"
              className="mx-5 d-flex align-items-center"
              style={{ gap: '15px' }}
            >
              <AccountPopover />
            </Typography>
          </Box>
        </Toolbar>
      </Container>
      {/* <Toolbar>
              
           
            </Toolbar> */}
    </AppBar>
  );
});

export default Topbar;
