import { memo } from 'react';
import Loader from '../../components/Loader';
import {
  Table,
  TableBody,
  TableHead,
  Button,
  TableCell,
  Stack,
  Paper,
  TableRow,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from '@mui/material';
import useGetConversionPoints from './convert-points-hook';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import React from 'react';

const ConvertPoints = () => {
  const {
    rows,
    handleClickOpen,
    handleClose,
    open,
    onSubmit,
    register,
    handleSubmit,
    loading,
  } = useGetConversionPoints();
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                  User Name
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                  Convert Points
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                  Total Points
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                  Date
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: 'bold'}}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row: any) => (
                <TableRow
                  key={row?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.user_name}
                  </TableCell>
                  <TableCell align="left">{row?.convert_points}</TableCell>
                  <TableCell align="left">
                    {row?.users_total_points}
                  </TableCell>
                  <TableCell align="left">
                  {new Date(row.created_at).toLocaleDateString()}
                  </TableCell>
                  {row.status === 'PENDING' ? (
                    <TableCell align="left">
                      <Stack direction="row" spacing={2}>
                        <Button
                          onClick={() => handleClickOpen(row?.id)}
                          variant="outlined"
                          color="success"
                          size="small"
                          startIcon={<CheckIcon />}
                        >
                          {' '}
                          Approve
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          startIcon={<ClearIcon />}
                        >
                          Decline
                        </Button>
                      </Stack>
                    </TableCell>
                  ) : (
                    <TableCell align="left">Approved</TableCell>
                  )}
                </TableRow>
              ))}
              <Dialog
                maxWidth="sm"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <DialogTitle>Convert Points</DialogTitle>
                  <DialogContent>
                    <DialogContentText></DialogContentText>
                    <input type="hidden" />
                    <TextField
                      margin="normal"
                      fullWidth
                      id="amount"
                      label="Amount"
                      {...register('approvedAmount', { required: true })}
                      autoComplete="amount"
                      autoFocus
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button color="primary" variant="contained" type="submit">
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default memo(ConvertPoints);
