import { Routes, Route } from 'react-router-dom';
import { memo } from 'react';
import Layout from '../../Containers/layout';
import User from '../NewUsers';
import UserList from '../UserList';
import ConvertPoints from '../ConvertPoints';
import Settings from '../Settings';
import Challenge from '../Challenge';
import Points from '../Points';
import Profile from '../Profile';
import { useAuthProvider } from '../../core/authContext';
import { Auth, Cache } from 'aws-amplify';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import Reports from "../Reports";
import React from 'react';
import Departments from '../Department';

const Dashboard = () => {
  const { getUser } = useAuthProvider();
  const user = getUser();
  const navigate = useNavigate();
  if (user && !user?.is_admin) {
    Cache.removeItem('session');
    Cache.removeItem('user');
    Auth.signOut();
    swal({
      text: 'You are not a admin user, please contact support ',
      icon: 'error',
    });
    navigate('/login');
  }
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<UserList />} />
        <Route path="/users" element={<User />} />
        <Route path="/convertPoints" element={<ConvertPoints />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/challenge" element={<Challenge />} />
        <Route path="/points" element={<Points />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/departments" element={<Departments />} />
      </Routes>
    </Layout>
  );
};

export default memo(Dashboard);
