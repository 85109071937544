import React from 'react';
import { useAuthProvider } from '../../core/authContext';
import {
  useDeleteUserMutation,
  useGetPropertyDepartmentsQuery,
  useGetPropertyUsersQuery,
} from '../../generated/graphql';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import swal from 'sweetalert';
import _ from 'lodash';

const useUserList = () => {
  const [open, setOpen] = React.useState(false);
  const [userID, setUserID] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState({} as any);

  //Get Authorized Users
  const { getUser } = useAuthProvider();
  const user = getUser();
  const { data, loading, refetch } = useGetPropertyUsersQuery({
    fetchPolicy: 'network-only',
    variables: {
      propertyID: user?.property_id,
    },
    skip: !user?.property_id,
  });

  const { data: deptData, loading: deptLoading } =
    useGetPropertyDepartmentsQuery({
      fetchPolicy: 'network-only',
      variables: {
        propertyID: user?.property_id,
      },
      skip: !user?.property_id,
    });

  const [deleteUser, { loading: loadingDeleteUser }] = useDeleteUserMutation();

  const handleClickOpen = async (row: any) => {
    setSelectedUser(row);
    setUserID(row.id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDeleteUser = async (id: string) => {
    try {
      const res = await deleteUser({
        variables: {
          userID: id,
        },
      });
      if (res.data?.deleteUser) {
        refetch?.();
        openSnackbar({ message: 'Deleted Successfully' }, NotifierType.Success);
      } else {
        openSnackbar(
          { message: 'Unable to delete this user, contact support!' },
          NotifierType.Error,
        );
        await swal({
          title: 'Error',
          text: 'Unable to delete this user, contact support!',
          icon: 'error',
        });
      }
    } catch (err: any) {
      console.log(err);
      openSnackbar({ message: err?.toString() || '' }, NotifierType.Error);
    }
  };

  const onClose = () => {
    setOpen(false);
    refetch();
  };
  return {
    users: data?.getPropertyUsers?.filter(user => !user?.deleted_at),
    deletedUsers: data?.getPropertyUsers?.filter(user => user?.deleted_at),
    departments: deptData?.propertyDepartments,
    departmentsMap: _.keyBy(deptData?.propertyDepartments, "id"),
    userID,
    loading:
       loading || loadingDeleteUser || deptLoading,
    handleClickOpen,
    handleClose,
    open,
    setOpen,
    onDeleteUser,
    selectedUser,
    onClose,
    refetch
  };
};

export default useUserList;
