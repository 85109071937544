import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import React from 'react';
import useDepartmentsHook from './departments-hook';
import EditIcon from '@mui/icons-material/Edit';
import { Controller } from 'react-hook-form';

const Departments = () => {
  const {
    loading,
    handleSubmit,
    control,
    errors,
    onSubmit,
    refetch,
    departments,
    openDepartmentForm,
    setOpenDepartmentForm,
    setEditDepartmentID,
    usersMap,
    editDepartmentID,
    usersByDepartment
  } = useDepartmentsHook();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Department Name',
      width: 150,
      editable: false,
    },
    {
      field: 'department_heads',
      headerName: 'Department Heads',
      width: 300,
      editable: false,
      renderCell: (params) => (
        <ul>
          {params.value?.map((v: string) => (
            <li>{usersMap[v]?.first_name + ' ' + usersMap[v]?.last_name}</li>
          ))}
        </ul>
      ),
    },
    {
      field: 'id',
      renderHeader: () => <strong>{'Action'}</strong>,
      width: 270,
      renderCell: (params) => (
        <Stack direction="row" spacing={2}>
          <Button
            onClick={() => {
              setEditDepartmentID(params.value);
              setOpenDepartmentForm(true);
            }}
            variant="outlined"
            color="success"
            size="small"
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
        </Stack>
      ),
    },
    {
      field: '',
      headerName: 'Department Members',
      align: 'right',
      width: 300,
      editable: false,
      renderCell: (params) => (
        <Typography sx={{textAlign: 'right'}}>
          {usersByDepartment?.[params.id]?.length || 0}
        </Typography>
      ),
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <Button
          startIcon={<ReplayIcon />}
          onClick={() => {
            refetch();
          }}
        >
          Refresh
        </Button>
        <GridToolbarQuickFilter />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <Box sx={{ height: 650, width: '100%' }}>
        <Stack direction="row" spacing={2} sx={{ m: 4 }}>
          <Button
            variant="contained"
            onClick={() => {
              setOpenDepartmentForm(true);
              setEditDepartmentID('');
            }}
          >
            Add New Department
          </Button>
        </Stack>
        <DataGrid
          rows={departments}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          loading={loading}
          components={{ Toolbar: CustomToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
      <Dialog
        open={openDepartmentForm}
        onClose={() => setOpenDepartmentForm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {editDepartmentID ? 'Update Department' : 'Create Department'}
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <FormControl fullWidth>
                <Controller
                  name="name"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Department Name"
                      value={value || ''}
                      onChange={onChange}
                      sx={{
                        mt: 2,
                      }}
                    />
                  )}
                  rules={{
                    required: 'Department name is required',
                  }}
                  control={control}
                />
                {errors.name && (
                  <Typography sx={{ color: 'red' }}>
                    {errors?.name?.message}
                  </Typography>
                )}
              </FormControl>
              <Stack direction="row" spacing={2}>
                <Button onClick={() => setOpenDepartmentForm(false)}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained">
                  Save
                </Button>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Departments;
