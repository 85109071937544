import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport,
  GridToolbarFilterButton, GridToolbarQuickFilter
} from "@mui/x-data-grid";
import {Button, Stack, Chip} from "@mui/material";
import ReplayIcon from '@mui/icons-material/Replay';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DocumentList from "../../components/DocumentList";
  
interface props {
  loading: boolean;
  entries: Array<any>;
  refetch: Function;
  handleClickOpen: Function;
  handleClickOpenPointDialog: Function;
  setCancelSubmission: Function;
  setRevertSubmission: Function;
}
const SubmissionsDataGrid = ({
  loading, 
  entries, 
  refetch, 
  handleClickOpen, 
  handleClickOpenPointDialog,
  setCancelSubmission,
  setRevertSubmission
}: props) => {
  const columns: GridColDef[] = [
    {
      field: 'employee_name',
      renderHeader: () => (
        <strong>
          {'Employee Name'}
        </strong>
      ),
      width: 200,
      editable: false,
    },
    {
      field: 'challenge_name',
      renderHeader: () => (
        <strong>
          {'Challenge Name'}
        </strong>
      ),
      width: 200,
      editable: false,
    },
    {
      field: 'challenge_terms',
      renderHeader: () => (
        <strong>
          {'Challenge Terms'}
        </strong>
      ),
      width: 150,
      editable: false,
      renderCell: (params) => <DocumentList image_url={params.value} handleClickOpen={handleClickOpen} />
    },
    {
      field: 'amount',
      renderHeader: () => (
        <strong>
          {'Amount'}
        </strong>
      ),
      width: 80,
      editable: false,
    },
    {
      field: 'receipt_number',
      renderHeader: () => (
        <strong>
          {'Reciept No'}
        </strong>
      ),
      width: 100,
      editable: false,
    },
    {
      field: 'submitted_documents',
      renderHeader: () => (
        <strong>
          {'Submitted Documents'}
        </strong>
      ),
      width: 200,
      editable: false,
      renderCell: (params) => <DocumentList image_url={params.value} handleClickOpen={handleClickOpen} />
    },
    {
      field: 'submission_date',
      renderHeader: () => (
        <strong>
          {'Date'}
        </strong>
      ),
      width: 100,
      editable: false,
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      field: 'status',
      renderHeader: () => (
        <strong>
          {'Action'}
        </strong>
      ),
      width: 270,
      renderCell: (params) => params.value === 'PENDING' ? (
        <Stack direction="row" spacing={2}>
          <Button
            onClick={() => handleClickOpenPointDialog(params.row.submission)}
            variant="outlined"
            color="success"
            size="small"
            startIcon={<CheckIcon />}
          >
            Approve
          </Button>
          <Button
            variant="outlined"
            color="error"
            size="small"
            startIcon={<ClearIcon />}
            onClick={() => setCancelSubmission(params.row.submission)}
          >
            Decline
          </Button>
        </Stack>
      ) : (
        <Stack direction="row" spacing={2}>
          <Chip 
            color={params.value === 'APPROVED' ? "success": "error"}
            size="small" 
            label={params.value === 'APPROVED' ? "Approved": "Declined"}
          />
          <Button 
            variant="outlined"
            size="small"
            color={params.value === 'APPROVED' ? "error": "success"}
            onClick={() => setRevertSubmission(params.row.submission)}
          >
            Revert
          </Button>
        </Stack>
      )
    }
  ];
  
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <Button startIcon={<ReplayIcon />} onClick={()=> {
          refetch();
        }}>
          Refresh
        </Button>
        <GridToolbarQuickFilter/>
        <GridToolbarExport/>
      </GridToolbarContainer>
    );
  };
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ height: '400px', width: '100%' }}>
        <DataGrid
          rows={entries}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          loading={loading}
          components={{ Toolbar: CustomToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
    </LocalizationProvider>
  );
};
  
export default SubmissionsDataGrid;
  