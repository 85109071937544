import { ApolloProvider } from '@apollo/client';
import AuthContext from './core/authContext';
import AppRouter from './pages/AppRouter';
import apolloClient from './core/apolloClient';
import initAWSAmplify from './core/awsAmplify';
import React from 'react';

initAWSAmplify();

const ApolloContainer = () => (
  <ApolloProvider client={apolloClient}>
    <AuthContext>
      <AppRouter />
    </AuthContext>
  </ApolloProvider>
);

const App = () => {
  return <ApolloContainer />;
};

export default App;
