import { Typography, Divider } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import FlagCircleOutlinedIcon from '@mui/icons-material/FlagCircleOutlined';
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupsIcon from '@mui/icons-material/Groups';
import { NavLink } from 'react-router-dom';
import {
  USERS,
  POINTS_REQUEST,
  SETTINGS,
  CHALLENGE,
  POINTS,
  REPORTS_REQUEST,
  DEPARTMENTS,
} from '../Urls';
import React from 'react';

const Navlist = () => {
  const NavData = [
    {
      name: 'Users',
      icon: <GroupIcon />,
      path: '/',
    },
    {
      name: 'New Users',
      icon: <GroupAddIcon />,
      path: USERS,
    },
    {
      name: 'Challenge',
      icon: <FlagCircleOutlinedIcon />,
      path: CHALLENGE,
    },
    {
      name: 'Points Submission',
      icon: <TelegramIcon />,
      path: POINTS,
    },
    {
      name: 'Points Conversion',
      icon: <BorderColorIcon />,
      path: POINTS_REQUEST,
    },
    {
      name: 'Reports',
      icon: <AssessmentIcon />,
      path: REPORTS_REQUEST,
    },
    {
      name: 'Departments',
      icon: <GroupsIcon />,
      path: DEPARTMENTS,
    },
    {
      name: 'Settings',
      icon: <SettingsIcon />,
      path: SETTINGS,
    },
  ];

  return (
    <div className="px-2">
      <Typography
        variant="h6"
        noWrap
        component="div"
        sx={{
          mx: 12,
          my: 3,
          display: { xs: 'none', md: 'flex' },
          fontWeight: 'bold',
        }}
      >
        HyPoints
      </Typography>
      <Divider />
      <div className="px-2 mt-3">
        {NavData.map((v) => {
          return (
            <NavLink to={v.path} className="sidebar-list" key={v.name}>
              <span>{v.icon}</span>
              <h4 className="mb-0">{v.name}</h4>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default Navlist;
