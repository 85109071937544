import React from 'react';
import { useAuthProvider } from '../../core/authContext';
import { useForm } from 'react-hook-form';
import {
  useGetConversionPointsRequestsQuery,
  useConvertsPointsMutation,
} from '../../generated/graphql';
import swal from 'sweetalert';

interface ConvertPoints {
  approvedAmount: number;
}
const useGetConversionPoints = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ConvertPoints>();
  const [open, setOpen] = React.useState(false);
  const [pointConversionID, setPointConversionID] = React.useState('');
  const [approvePointsConversion, { loading: loadingPointConversion }] =
    useConvertsPointsMutation();

  const handleClickOpen = (pointConversionID: any) => {
    setPointConversionID(pointConversionID);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { getUser } = useAuthProvider();
  const user = getUser();
  const { data, loading, refetch } = useGetConversionPointsRequestsQuery({
    fetchPolicy: 'network-only',
    variables: {
      propertyID: user?.property_id,
    },
    skip: !user?.property_id,
  });

  const status = ['PENDING', 'APPROVED'];

  data?.propertyPointsConversions?.sort((a: any, b: any) => {
    return status.indexOf(a?.status) - status.indexOf(b?.status);
  });

  //approved Points conversion
  const onSubmit = async (data: ConvertPoints) => {
    try {
      await approvePointsConversion({
        variables: {
          pointConversionID: pointConversionID,
          approvedAmount: data?.approvedAmount,
        },
      });
      setOpen(false);
      swal({
        title: 'Points Approved',
        icon: 'success',
      });
      refetch();
    } catch (err: any) {
      console.log(err);
    }
  };
  return {
    loading: loadingPointConversion || loading,
    rows: data?.propertyPointsConversions,
    register,
    handleSubmit,
    errors,
    handleClickOpen,
    handleClose,
    open,
    onSubmit,
  };
};

export default useGetConversionPoints;
