import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AuthirizeUserInput = {
  department_id?: InputMaybe<Scalars['ID']>;
  is_admin: Scalars['Boolean'];
  is_department_head?: InputMaybe<Scalars['Boolean']>;
  is_manager: Scalars['Boolean'];
  is_supervisor: Scalars['Boolean'];
  reports_to?: InputMaybe<Scalars['ID']>;
  user_id: Scalars['ID'];
};

export type Challenge = {
  __typename?: 'Challenge';
  created_at?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['String']>;
  deleted_by?: Maybe<Scalars['String']>;
  department_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  doc_urls?: Maybe<Array<Maybe<Scalars['String']>>>;
  end_date?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  max_reward_points?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Float']>;
  property?: Maybe<Property>;
  property_id: Scalars['ID'];
  reward_type?: Maybe<ChallengeRewardType>;
  start_date?: Maybe<Scalars['String']>;
  status?: Maybe<ChallengeStatus>;
  sub_challenges?: Maybe<Array<Maybe<SubChallenge>>>;
  submitted_points?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
};

export type ChallengeInput = {
  department_id?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  doc_urls?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  end_date?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  max_reward_points?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  points: Scalars['Float'];
  property_id: Scalars['ID'];
  reward_type?: InputMaybe<ChallengeRewardType>;
  start_date?: InputMaybe<Scalars['String']>;
};

export enum ChallengeRewardType {
  Direct = 'DIRECT',
  Percent = 'PERCENT',
}

export enum ChallengeStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type Department = {
  __typename?: 'Department';
  created_at?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['String']>;
  deleted_by?: Maybe<Scalars['String']>;
  department_heads?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  property_id: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
};

export type DepartmentInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  property_id: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  approvePointsConversion?: Maybe<Scalars['String']>;
  approvePointsSubmission?: Maybe<Scalars['String']>;
  authorizeNewUser?: Maybe<Scalars['String']>;
  declinePointsConversion?: Maybe<Scalars['String']>;
  declinePointsSubmission?: Maybe<Scalars['String']>;
  deleteChallenge?: Maybe<Scalars['String']>;
  deleteSubChallenge?: Maybe<Scalars['String']>;
  deleteUser?: Maybe<Scalars['String']>;
  revertApprovedSubmission?: Maybe<Scalars['String']>;
  revertDeclinedSubmission?: Maybe<Scalars['String']>;
  updateChallengeStatus?: Maybe<Scalars['String']>;
  updatePushNotificationToken?: Maybe<Scalars['String']>;
  updateUserData?: Maybe<Scalars['String']>;
  updateUserProfile?: Maybe<Scalars['String']>;
  upsertChallenge?: Maybe<Challenge>;
  upsertDepartment?: Maybe<Scalars['String']>;
  upsertPointsConversion?: Maybe<Scalars['String']>;
  upsertPointsSubmission?: Maybe<PointsSubmission>;
  upsertPointsTransfer?: Maybe<Scalars['String']>;
  upsertProperty?: Maybe<Property>;
  upsertSubChallenge?: Maybe<SubChallenge>;
  upsertUser?: Maybe<User>;
};

export type MutationApprovePointsConversionArgs = {
  approvedAmount: Scalars['Int'];
  pointConversionID: Scalars['ID'];
};

export type MutationApprovePointsSubmissionArgs = {
  approvedPoints: Scalars['Int'];
  pointSubmissionID: Scalars['ID'];
};

export type MutationAuthorizeNewUserArgs = {
  authorizeUserInput?: InputMaybe<AuthirizeUserInput>;
};

export type MutationDeclinePointsConversionArgs = {
  pointConversionID: Scalars['ID'];
};

export type MutationDeclinePointsSubmissionArgs = {
  declineReason?: InputMaybe<Scalars['String']>;
  pointSubmissionID: Scalars['ID'];
};

export type MutationDeleteChallengeArgs = {
  challengeID: Scalars['String'];
};

export type MutationDeleteSubChallengeArgs = {
  subChallengeID: Scalars['String'];
};

export type MutationDeleteUserArgs = {
  userID: Scalars['ID'];
};

export type MutationRevertApprovedSubmissionArgs = {
  pointSubmissionID: Scalars['ID'];
};

export type MutationRevertDeclinedSubmissionArgs = {
  pointSubmissionID: Scalars['ID'];
};

export type MutationUpdateChallengeStatusArgs = {
  challengeID: Scalars['String'];
  challengeStatus: ChallengeStatus;
};

export type MutationUpdatePushNotificationTokenArgs = {
  token: Scalars['String'];
  userID: Scalars['ID'];
};

export type MutationUpdateUserDataArgs = {
  updateUserInput?: InputMaybe<UpdateUserInput>;
};

export type MutationUpdateUserProfileArgs = {
  profileUrl: Scalars['String'];
  userID: Scalars['ID'];
};

export type MutationUpsertChallengeArgs = {
  challengeInput?: InputMaybe<ChallengeInput>;
};

export type MutationUpsertDepartmentArgs = {
  departmentInput: DepartmentInput;
};

export type MutationUpsertPointsConversionArgs = {
  pointConversionInput?: InputMaybe<PointConversionInput>;
};

export type MutationUpsertPointsSubmissionArgs = {
  submissionInput?: InputMaybe<PointsSubmissionInput>;
};

export type MutationUpsertPointsTransferArgs = {
  pointsTransferInput: PointsTransferInput;
};

export type MutationUpsertPropertyArgs = {
  propertyInput: PropertyInput;
};

export type MutationUpsertSubChallengeArgs = {
  subChallengeInput?: InputMaybe<SubChallengeInput>;
};

export type MutationUpsertUserArgs = {
  userInput: UserInput;
};

export type PointConversionInput = {
  convert_points: Scalars['Int'];
  id?: InputMaybe<Scalars['ID']>;
  property_id: Scalars['String'];
  user_id: Scalars['ID'];
};

export type PointsConversion = {
  __typename?: 'PointsConversion';
  approved_amount?: Maybe<Scalars['Int']>;
  convert_points: Scalars['Int'];
  created_at?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['String']>;
  deleted_by?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  property_id?: Maybe<Scalars['String']>;
  status?: Maybe<PointsConversionStatus>;
  updated_at?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id: Scalars['ID'];
  user_name?: Maybe<Scalars['String']>;
  users_total_points?: Maybe<Scalars['Int']>;
};

export enum PointsConversionStatus {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
}

export type PointsSubmission = {
  __typename?: 'PointsSubmission';
  amount: Scalars['Int'];
  approved_points?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['String']>;
  challenge_name?: Maybe<Scalars['String']>;
  challenge_terms?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  decline_reason?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['String']>;
  deleted_by?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image_url?: Maybe<Array<Maybe<Scalars['String']>>>;
  property_id?: Maybe<Scalars['String']>;
  reference_receipt: Scalars['String'];
  status: PointsSubmissionStatus;
  status_changed_by?: Maybe<Scalars['String']>;
  sub_challenge_id?: Maybe<Scalars['String']>;
  sub_challenge_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};

export type PointsSubmissionInput = {
  amount: Scalars['Int'];
  challenge_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  image_url?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  property_id?: InputMaybe<Scalars['String']>;
  reference_receipt: Scalars['String'];
  status?: InputMaybe<PointsSubmissionStatus>;
  sub_challenge_id?: InputMaybe<Scalars['String']>;
  user_id: Scalars['String'];
};

export enum PointsSubmissionStatus {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
}

export type PointsTransfer = {
  __typename?: 'PointsTransfer';
  created_at?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['String']>;
  deleted_by?: Maybe<Scalars['String']>;
  from_user_id: Scalars['String'];
  id: Scalars['ID'];
  to_user_id: Scalars['String'];
  transferred_points: Scalars['Int'];
  updated_at?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
};

export type PointsTransferInput = {
  from_user_id: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  to_user_id: Scalars['String'];
  transferred_points: Scalars['Int'];
};

export type Property = {
  __typename?: 'Property';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['String']>;
  deleted_by?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

export type PropertyInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  activeChallenges?: Maybe<Array<Maybe<Challenge>>>;
  allChallenges?: Maybe<Array<Maybe<Challenge>>>;
  approvePointRequests?: Maybe<Array<Maybe<PointsSubmission>>>;
  challenge?: Maybe<Challenge>;
  challenges?: Maybe<Array<Maybe<Challenge>>>;
  getNewUsers?: Maybe<Array<Maybe<User>>>;
  getProperties?: Maybe<Array<Maybe<Property>>>;
  getPropertyUsers?: Maybe<Array<Maybe<User>>>;
  getUploadSignedURL?: Maybe<SignedUrl>;
  me?: Maybe<User>;
  pointsSubmissionList?: Maybe<Array<Maybe<PointsSubmission>>>;
  propertyDepartments?: Maybe<Array<Maybe<Department>>>;
  propertyPointsConversions?: Maybe<Array<Maybe<PointsConversion>>>;
  propertyPointsSubmissions?: Maybe<Array<Maybe<PointsSubmission>>>;
  propertyPointsSubmissionsWithDateRange?: Maybe<
    Array<Maybe<PointsSubmission>>
  >;
  userPointsConversions?: Maybe<Array<Maybe<PointsConversion>>>;
  userPointsSubmissions?: Maybe<Array<Maybe<PointsSubmission>>>;
  userPointsTransfers?: Maybe<Array<Maybe<PointsTransfer>>>;
  userPushNotificationToken?: Maybe<Scalars['String']>;
};

export type QueryActiveChallengesArgs = {
  userID: Scalars['ID'];
};

export type QueryAllChallengesArgs = {
  propertyID: Scalars['ID'];
};

export type QueryApprovePointRequestsArgs = {
  userID: Scalars['ID'];
};

export type QueryChallengeArgs = {
  id: Scalars['ID'];
};

export type QueryChallengesArgs = {
  propertyID: Scalars['ID'];
};

export type QueryGetNewUsersArgs = {
  propertyID: Scalars['ID'];
};

export type QueryGetPropertyUsersArgs = {
  propertyID: Scalars['ID'];
};

export type QueryGetUploadSignedUrlArgs = {
  extension: Scalars['String'];
  fileType: Scalars['String'];
};

export type QueryPointsSubmissionListArgs = {
  userID: Scalars['String'];
};

export type QueryPropertyDepartmentsArgs = {
  propertyID: Scalars['ID'];
};

export type QueryPropertyPointsConversionsArgs = {
  propertyID: Scalars['ID'];
};

export type QueryPropertyPointsSubmissionsArgs = {
  propertyID: Scalars['ID'];
};

export type QueryPropertyPointsSubmissionsWithDateRangeArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  propertyID: Scalars['ID'];
  startDate?: InputMaybe<Scalars['String']>;
};

export type QueryUserPointsConversionsArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  userID: Scalars['ID'];
};

export type QueryUserPointsSubmissionsArgs = {
  userID: Scalars['ID'];
};

export type QueryUserPointsTransfersArgs = {
  userID: Scalars['ID'];
};

export type QueryUserPushNotificationTokenArgs = {
  userID: Scalars['ID'];
};

export type SignedUrl = {
  __typename?: 'SignedURL';
  presigned_upload_url?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SubChallenge = {
  __typename?: 'SubChallenge';
  challenge?: Maybe<Challenge>;
  challenge_id: Scalars['ID'];
  created_at?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['String']>;
  deleted_by?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  max_reward_points?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Float']>;
  reward_type?: Maybe<ChallengeRewardType>;
  updated_at?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  user_has_submission?: Maybe<Scalars['Boolean']>;
};

export type SubChallengeInput = {
  challenge_id: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  max_reward_points?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  points: Scalars['Float'];
  reward_type?: InputMaybe<ChallengeRewardType>;
};

export type UpdateUserInput = {
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  user_id: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  created_at?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['String']>;
  deleted_by?: Maybe<Scalars['String']>;
  department_id?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  employee_id?: Maybe<Scalars['ID']>;
  first_name: Scalars['String'];
  id: Scalars['ID'];
  is_admin?: Maybe<Scalars['Boolean']>;
  is_authorized?: Maybe<Scalars['Boolean']>;
  is_declined?: Maybe<Scalars['Boolean']>;
  is_department_head?: Maybe<Scalars['Boolean']>;
  is_manager?: Maybe<Scalars['Boolean']>;
  is_supervisor?: Maybe<Scalars['Boolean']>;
  last_name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  profile_url?: Maybe<Scalars['String']>;
  property_id?: Maybe<Scalars['String']>;
  push_notification_token?: Maybe<Scalars['String']>;
  reports_to?: Maybe<Scalars['String']>;
  total_points?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
};

export type UserInput = {
  email?: InputMaybe<Scalars['String']>;
  first_name: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  is_admin?: InputMaybe<Scalars['Boolean']>;
  is_authorized?: InputMaybe<Scalars['Boolean']>;
  last_name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  property_id: Scalars['String'];
};

export type UsersActiveChallenge = {
  __typename?: 'UsersActiveChallenge';
  department_id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  max_reward_points?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Float']>;
  property?: Maybe<Property>;
  property_id: Scalars['ID'];
  reward_type?: Maybe<ChallengeRewardType>;
  status?: Maybe<Scalars['String']>;
  sub_challenges?: Maybe<Array<Maybe<SubChallenge>>>;
  submitted_points?: Maybe<Scalars['Float']>;
};

export type AddChallengeMutationVariables = Exact<{
  challengeInput: ChallengeInput;
}>;

export type AddChallengeMutation = {
  __typename?: 'Mutation';
  upsertChallenge?: {
    __typename?: 'Challenge';
    id: string;
    property_id: string;
    department_id?: string | null;
    name?: string | null;
    description?: string | null;
    points?: number | null;
    reward_type?: ChallengeRewardType | null;
    max_reward_points?: number | null;
    doc_urls?: Array<string | null> | null;
  } | null;
};

export type AddSubChallengeMutationVariables = Exact<{
  subChallengeInput: SubChallengeInput;
}>;

export type AddSubChallengeMutation = {
  __typename?: 'Mutation';
  upsertSubChallenge?: {
    __typename?: 'SubChallenge';
    id: string;
    challenge_id: string;
    name?: string | null;
    description?: string | null;
    points?: number | null;
    reward_type?: ChallengeRewardType | null;
    max_reward_points?: number | null;
  } | null;
};

export type DeleteChallengeMutationVariables = Exact<{
  challengeID: Scalars['String'];
}>;

export type DeleteChallengeMutation = {
  __typename?: 'Mutation';
  deleteChallenge?: string | null;
};

export type DeleteSubChallengeMutationVariables = Exact<{
  subChallengeID: Scalars['String'];
}>;

export type DeleteSubChallengeMutation = {
  __typename?: 'Mutation';
  deleteSubChallenge?: string | null;
};

export type UpdateChallengeStatusMutationVariables = Exact<{
  challengeID: Scalars['String'];
  challengeStatus: ChallengeStatus;
}>;

export type UpdateChallengeStatusMutation = {
  __typename?: 'Mutation';
  updateChallengeStatus?: string | null;
};

export type GetChallengesQueryVariables = Exact<{
  propertyID: Scalars['ID'];
}>;

export type GetChallengesQuery = {
  __typename?: 'Query';
  challenges?: Array<{
    __typename?: 'Challenge';
    id: string;
    property_id: string;
    department_id?: string | null;
    name?: string | null;
    description?: string | null;
    points?: number | null;
    status?: ChallengeStatus | null;
    doc_urls?: Array<string | null> | null;
    sub_challenges?: Array<{
      __typename?: 'SubChallenge';
      id: string;
      challenge_id: string;
      name?: string | null;
      description?: string | null;
      points?: number | null;
    } | null> | null;
  } | null> | null;
};

export type GetChallengeQueryVariables = Exact<{
  challengeID: Scalars['ID'];
}>;

export type GetChallengeQuery = {
  __typename?: 'Query';
  challenge?: {
    __typename?: 'Challenge';
    id: string;
    property_id: string;
    department_id?: string | null;
    name?: string | null;
    description?: string | null;
    points?: number | null;
    status?: ChallengeStatus | null;
    reward_type?: ChallengeRewardType | null;
    max_reward_points?: number | null;
    doc_urls?: Array<string | null> | null;
    sub_challenges?: Array<{
      __typename?: 'SubChallenge';
      id: string;
      challenge_id: string;
      name?: string | null;
      description?: string | null;
      points?: number | null;
      reward_type?: ChallengeRewardType | null;
      max_reward_points?: number | null;
    } | null> | null;
  } | null;
};

export type GetPropertyDepartmentsQueryVariables = Exact<{
  propertyID: Scalars['ID'];
}>;

export type GetPropertyDepartmentsQuery = {
  __typename?: 'Query';
  propertyDepartments?: Array<{
    __typename?: 'Department';
    id: string;
    name: string;
    property_id: string;
  } | null> | null;
};

export type ConvertsPointsMutationVariables = Exact<{
  pointConversionID: Scalars['ID'];
  approvedAmount: Scalars['Int'];
}>;

export type ConvertsPointsMutation = {
  __typename?: 'Mutation';
  approvePointsConversion?: string | null;
};

export type GetConversionPointsRequestsQueryVariables = Exact<{
  propertyID: Scalars['ID'];
}>;

export type GetConversionPointsRequestsQuery = {
  __typename?: 'Query';
  propertyPointsConversions?: Array<{
    __typename?: 'PointsConversion';
    id: string;
    user_id: string;
    convert_points: number;
    user_name?: string | null;
    users_total_points?: number | null;
    status?: PointsConversionStatus | null;
    created_at?: string | null;
  } | null> | null;
};

export type GetDepartmentsQueryVariables = Exact<{
  propertyID: Scalars['ID'];
}>;

export type GetDepartmentsQuery = {
  __typename?: 'Query';
  propertyDepartments?: Array<{
    __typename?: 'Department';
    id: string;
    property_id: string;
    name: string;
    department_heads?: Array<string | null> | null;
  } | null> | null;
};

export type UpsertDepartmentMutationVariables = Exact<{
  input: DepartmentInput;
}>;

export type UpsertDepartmentMutation = {
  __typename?: 'Mutation';
  upsertDepartment?: string | null;
};

export type AuthorizeNewUserMutationVariables = Exact<{
  authorizeUserInput: AuthirizeUserInput;
}>;

export type AuthorizeNewUserMutation = {
  __typename?: 'Mutation';
  authorizeNewUser?: string | null;
};

export type GetNewUsersQueryVariables = Exact<{
  propertyID: Scalars['ID'];
}>;

export type GetNewUsersQuery = {
  __typename?: 'Query';
  getNewUsers?: Array<{
    __typename?: 'User';
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    property_id?: string | null;
    is_authorized?: boolean | null;
    created_at?: string | null;
    employee_id?: string | null;
  } | null> | null;
};

export type SubmitPointsMutationVariables = Exact<{
  pointSubmissionID: Scalars['ID'];
  approvedPoints: Scalars['Int'];
}>;

export type SubmitPointsMutation = {
  __typename?: 'Mutation';
  approvePointsSubmission?: string | null;
};

export type DeclinePointsSubmissionMutationVariables = Exact<{
  pointSubmissionID: Scalars['ID'];
  declineReason?: InputMaybe<Scalars['String']>;
}>;

export type DeclinePointsSubmissionMutation = {
  __typename?: 'Mutation';
  declinePointsSubmission?: string | null;
};

export type RevertApprovedSubmissionMutationVariables = Exact<{
  pointSubmissionID: Scalars['ID'];
}>;

export type RevertApprovedSubmissionMutation = {
  __typename?: 'Mutation';
  revertApprovedSubmission?: string | null;
};

export type RevertDeclinedSubmissionMutationVariables = Exact<{
  pointSubmissionID: Scalars['ID'];
}>;

export type RevertDeclinedSubmissionMutation = {
  __typename?: 'Mutation';
  revertDeclinedSubmission?: string | null;
};

export type GetPointsSubmissionsQueryVariables = Exact<{
  propertyID: Scalars['ID'];
}>;

export type GetPointsSubmissionsQuery = {
  __typename?: 'Query';
  propertyPointsSubmissions?: Array<{
    __typename?: 'PointsSubmission';
    id: string;
    user_id: string;
    amount: number;
    reference_receipt: string;
    status: PointsSubmissionStatus;
    challenge_id?: string | null;
    sub_challenge_id?: string | null;
    status_changed_by?: string | null;
    image_url?: Array<string | null> | null;
    challenge_name?: string | null;
    sub_challenge_name?: string | null;
    created_at?: string | null;
    challenge_terms?: Array<string | null> | null;
  } | null> | null;
};

export type UserProfileMutationVariables = Exact<{
  userID: Scalars['ID'];
  profileUrl: Scalars['String'];
}>;

export type UserProfileMutation = {
  __typename?: 'Mutation';
  updateUserProfile?: string | null;
};

export type UpdateUserMutationVariables = Exact<{
  updateUserInput: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUserData?: string | null;
};

export type GetUploadSignedUrlQueryVariables = Exact<{
  fileType: Scalars['String'];
  extension: Scalars['String'];
}>;

export type GetUploadSignedUrlQuery = {
  __typename?: 'Query';
  getUploadSignedURL?: {
    __typename?: 'SignedURL';
    url?: string | null;
    presigned_upload_url?: string | null;
  } | null;
};

export type GetPointsSubmissionsWithDateRangeQueryVariables = Exact<{
  propertyID: Scalars['ID'];
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
}>;

export type GetPointsSubmissionsWithDateRangeQuery = {
  __typename?: 'Query';
  propertyPointsSubmissionsWithDateRange?: Array<{
    __typename?: 'PointsSubmission';
    id: string;
    user_id: string;
    amount: number;
    reference_receipt: string;
    status: PointsSubmissionStatus;
    challenge_id?: string | null;
    sub_challenge_id?: string | null;
    status_changed_by?: string | null;
    image_url?: Array<string | null> | null;
    challenge_name?: string | null;
    sub_challenge_name?: string | null;
    created_at?: string | null;
    updated_by?: string | null;
    approved_points?: number | null;
  } | null> | null;
};

export type GetAllChallengesQueryVariables = Exact<{
  propertyID: Scalars['ID'];
}>;

export type GetAllChallengesQuery = {
  __typename?: 'Query';
  allChallenges?: Array<{
    __typename?: 'Challenge';
    id: string;
    property_id: string;
    department_id?: string | null;
    name?: string | null;
    description?: string | null;
    points?: number | null;
    status?: ChallengeStatus | null;
    doc_urls?: Array<string | null> | null;
  } | null> | null;
};

export type DeleteUserMutationVariables = Exact<{
  userID: Scalars['ID'];
}>;

export type DeleteUserMutation = {
  __typename?: 'Mutation';
  deleteUser?: string | null;
};

export type GetPropertyUsersQueryVariables = Exact<{
  propertyID: Scalars['ID'];
}>;

export type GetPropertyUsersQuery = {
  __typename?: 'Query';
  getPropertyUsers?: Array<{
    __typename?: 'User';
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    property_id?: string | null;
    total_points?: number | null;
    created_at?: string | null;
    is_authorized?: boolean | null;
    is_manager?: boolean | null;
    is_supervisor?: boolean | null;
    is_admin?: boolean | null;
    reports_to?: string | null;
    deleted_at?: string | null;
    department_id?: string | null;
    is_department_head?: boolean | null;
    employee_id?: string | null;
  } | null> | null;
};

export const AddChallengeDocument = gql`
  mutation addChallenge($challengeInput: ChallengeInput!) {
    upsertChallenge(challengeInput: $challengeInput) {
      id
      property_id
      department_id
      name
      description
      points
      reward_type
      max_reward_points
      doc_urls
    }
  }
`;
export type AddChallengeMutationFn = ApolloReactCommon.MutationFunction<
  AddChallengeMutation,
  AddChallengeMutationVariables
>;

/**
 * __useAddChallengeMutation__
 *
 * To run a mutation, you first call `useAddChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChallengeMutation, { data, loading, error }] = useAddChallengeMutation({
 *   variables: {
 *      challengeInput: // value for 'challengeInput'
 *   },
 * });
 */
export function useAddChallengeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddChallengeMutation,
    AddChallengeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddChallengeMutation,
    AddChallengeMutationVariables
  >(AddChallengeDocument, options);
}
export type AddChallengeMutationHookResult = ReturnType<
  typeof useAddChallengeMutation
>;
export type AddChallengeMutationResult =
  ApolloReactCommon.MutationResult<AddChallengeMutation>;
export type AddChallengeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddChallengeMutation,
  AddChallengeMutationVariables
>;
export const AddSubChallengeDocument = gql`
  mutation addSubChallenge($subChallengeInput: SubChallengeInput!) {
    upsertSubChallenge(subChallengeInput: $subChallengeInput) {
      id
      challenge_id
      name
      description
      points
      reward_type
      max_reward_points
    }
  }
`;
export type AddSubChallengeMutationFn = ApolloReactCommon.MutationFunction<
  AddSubChallengeMutation,
  AddSubChallengeMutationVariables
>;

/**
 * __useAddSubChallengeMutation__
 *
 * To run a mutation, you first call `useAddSubChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubChallengeMutation, { data, loading, error }] = useAddSubChallengeMutation({
 *   variables: {
 *      subChallengeInput: // value for 'subChallengeInput'
 *   },
 * });
 */
export function useAddSubChallengeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddSubChallengeMutation,
    AddSubChallengeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddSubChallengeMutation,
    AddSubChallengeMutationVariables
  >(AddSubChallengeDocument, options);
}
export type AddSubChallengeMutationHookResult = ReturnType<
  typeof useAddSubChallengeMutation
>;
export type AddSubChallengeMutationResult =
  ApolloReactCommon.MutationResult<AddSubChallengeMutation>;
export type AddSubChallengeMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AddSubChallengeMutation,
    AddSubChallengeMutationVariables
  >;
export const DeleteChallengeDocument = gql`
  mutation deleteChallenge($challengeID: String!) {
    deleteChallenge(challengeID: $challengeID)
  }
`;
export type DeleteChallengeMutationFn = ApolloReactCommon.MutationFunction<
  DeleteChallengeMutation,
  DeleteChallengeMutationVariables
>;

/**
 * __useDeleteChallengeMutation__
 *
 * To run a mutation, you first call `useDeleteChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChallengeMutation, { data, loading, error }] = useDeleteChallengeMutation({
 *   variables: {
 *      challengeID: // value for 'challengeID'
 *   },
 * });
 */
export function useDeleteChallengeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteChallengeMutation,
    DeleteChallengeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteChallengeMutation,
    DeleteChallengeMutationVariables
  >(DeleteChallengeDocument, options);
}
export type DeleteChallengeMutationHookResult = ReturnType<
  typeof useDeleteChallengeMutation
>;
export type DeleteChallengeMutationResult =
  ApolloReactCommon.MutationResult<DeleteChallengeMutation>;
export type DeleteChallengeMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteChallengeMutation,
    DeleteChallengeMutationVariables
  >;
export const DeleteSubChallengeDocument = gql`
  mutation deleteSubChallenge($subChallengeID: String!) {
    deleteSubChallenge(subChallengeID: $subChallengeID)
  }
`;
export type DeleteSubChallengeMutationFn = ApolloReactCommon.MutationFunction<
  DeleteSubChallengeMutation,
  DeleteSubChallengeMutationVariables
>;

/**
 * __useDeleteSubChallengeMutation__
 *
 * To run a mutation, you first call `useDeleteSubChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubChallengeMutation, { data, loading, error }] = useDeleteSubChallengeMutation({
 *   variables: {
 *      subChallengeID: // value for 'subChallengeID'
 *   },
 * });
 */
export function useDeleteSubChallengeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteSubChallengeMutation,
    DeleteSubChallengeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteSubChallengeMutation,
    DeleteSubChallengeMutationVariables
  >(DeleteSubChallengeDocument, options);
}
export type DeleteSubChallengeMutationHookResult = ReturnType<
  typeof useDeleteSubChallengeMutation
>;
export type DeleteSubChallengeMutationResult =
  ApolloReactCommon.MutationResult<DeleteSubChallengeMutation>;
export type DeleteSubChallengeMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteSubChallengeMutation,
    DeleteSubChallengeMutationVariables
  >;
export const UpdateChallengeStatusDocument = gql`
  mutation updateChallengeStatus(
    $challengeID: String!
    $challengeStatus: ChallengeStatus!
  ) {
    updateChallengeStatus(
      challengeID: $challengeID
      challengeStatus: $challengeStatus
    )
  }
`;
export type UpdateChallengeStatusMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateChallengeStatusMutation,
    UpdateChallengeStatusMutationVariables
  >;

/**
 * __useUpdateChallengeStatusMutation__
 *
 * To run a mutation, you first call `useUpdateChallengeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChallengeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChallengeStatusMutation, { data, loading, error }] = useUpdateChallengeStatusMutation({
 *   variables: {
 *      challengeID: // value for 'challengeID'
 *      challengeStatus: // value for 'challengeStatus'
 *   },
 * });
 */
export function useUpdateChallengeStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateChallengeStatusMutation,
    UpdateChallengeStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateChallengeStatusMutation,
    UpdateChallengeStatusMutationVariables
  >(UpdateChallengeStatusDocument, options);
}
export type UpdateChallengeStatusMutationHookResult = ReturnType<
  typeof useUpdateChallengeStatusMutation
>;
export type UpdateChallengeStatusMutationResult =
  ApolloReactCommon.MutationResult<UpdateChallengeStatusMutation>;
export type UpdateChallengeStatusMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateChallengeStatusMutation,
    UpdateChallengeStatusMutationVariables
  >;
export const GetChallengesDocument = gql`
  query getChallenges($propertyID: ID!) {
    challenges(propertyID: $propertyID) {
      id
      property_id
      department_id
      name
      description
      points
      status
      sub_challenges {
        id
        challenge_id
        name
        description
        points
      }
      doc_urls
    }
  }
`;

/**
 * __useGetChallengesQuery__
 *
 * To run a query within a React component, call `useGetChallengesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengesQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetChallengesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetChallengesQuery,
    GetChallengesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetChallengesQuery,
    GetChallengesQueryVariables
  >(GetChallengesDocument, options);
}
export function useGetChallengesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetChallengesQuery,
    GetChallengesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetChallengesQuery,
    GetChallengesQueryVariables
  >(GetChallengesDocument, options);
}
export type GetChallengesQueryHookResult = ReturnType<
  typeof useGetChallengesQuery
>;
export type GetChallengesLazyQueryHookResult = ReturnType<
  typeof useGetChallengesLazyQuery
>;
export type GetChallengesQueryResult = ApolloReactCommon.QueryResult<
  GetChallengesQuery,
  GetChallengesQueryVariables
>;
export const GetChallengeDocument = gql`
  query getChallenge($challengeID: ID!) {
    challenge(id: $challengeID) {
      id
      property_id
      department_id
      name
      description
      points
      status
      reward_type
      max_reward_points
      sub_challenges {
        id
        challenge_id
        name
        description
        points
        reward_type
        max_reward_points
      }
      doc_urls
    }
  }
`;

/**
 * __useGetChallengeQuery__
 *
 * To run a query within a React component, call `useGetChallengeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengeQuery({
 *   variables: {
 *      challengeID: // value for 'challengeID'
 *   },
 * });
 */
export function useGetChallengeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetChallengeQuery,
    GetChallengeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetChallengeQuery,
    GetChallengeQueryVariables
  >(GetChallengeDocument, options);
}
export function useGetChallengeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetChallengeQuery,
    GetChallengeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetChallengeQuery,
    GetChallengeQueryVariables
  >(GetChallengeDocument, options);
}
export type GetChallengeQueryHookResult = ReturnType<
  typeof useGetChallengeQuery
>;
export type GetChallengeLazyQueryHookResult = ReturnType<
  typeof useGetChallengeLazyQuery
>;
export type GetChallengeQueryResult = ApolloReactCommon.QueryResult<
  GetChallengeQuery,
  GetChallengeQueryVariables
>;
export const GetPropertyDepartmentsDocument = gql`
  query getPropertyDepartments($propertyID: ID!) {
    propertyDepartments(propertyID: $propertyID) {
      id
      name
      property_id
    }
  }
`;

/**
 * __useGetPropertyDepartmentsQuery__
 *
 * To run a query within a React component, call `useGetPropertyDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyDepartmentsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetPropertyDepartmentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPropertyDepartmentsQuery,
    GetPropertyDepartmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPropertyDepartmentsQuery,
    GetPropertyDepartmentsQueryVariables
  >(GetPropertyDepartmentsDocument, options);
}
export function useGetPropertyDepartmentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPropertyDepartmentsQuery,
    GetPropertyDepartmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPropertyDepartmentsQuery,
    GetPropertyDepartmentsQueryVariables
  >(GetPropertyDepartmentsDocument, options);
}
export type GetPropertyDepartmentsQueryHookResult = ReturnType<
  typeof useGetPropertyDepartmentsQuery
>;
export type GetPropertyDepartmentsLazyQueryHookResult = ReturnType<
  typeof useGetPropertyDepartmentsLazyQuery
>;
export type GetPropertyDepartmentsQueryResult = ApolloReactCommon.QueryResult<
  GetPropertyDepartmentsQuery,
  GetPropertyDepartmentsQueryVariables
>;
export const ConvertsPointsDocument = gql`
  mutation convertsPoints($pointConversionID: ID!, $approvedAmount: Int!) {
    approvePointsConversion(
      pointConversionID: $pointConversionID
      approvedAmount: $approvedAmount
    )
  }
`;
export type ConvertsPointsMutationFn = ApolloReactCommon.MutationFunction<
  ConvertsPointsMutation,
  ConvertsPointsMutationVariables
>;

/**
 * __useConvertsPointsMutation__
 *
 * To run a mutation, you first call `useConvertsPointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertsPointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertsPointsMutation, { data, loading, error }] = useConvertsPointsMutation({
 *   variables: {
 *      pointConversionID: // value for 'pointConversionID'
 *      approvedAmount: // value for 'approvedAmount'
 *   },
 * });
 */
export function useConvertsPointsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConvertsPointsMutation,
    ConvertsPointsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ConvertsPointsMutation,
    ConvertsPointsMutationVariables
  >(ConvertsPointsDocument, options);
}
export type ConvertsPointsMutationHookResult = ReturnType<
  typeof useConvertsPointsMutation
>;
export type ConvertsPointsMutationResult =
  ApolloReactCommon.MutationResult<ConvertsPointsMutation>;
export type ConvertsPointsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ConvertsPointsMutation,
    ConvertsPointsMutationVariables
  >;
export const GetConversionPointsRequestsDocument = gql`
  query getConversionPointsRequests($propertyID: ID!) {
    propertyPointsConversions(propertyID: $propertyID) {
      id
      user_id
      convert_points
      user_name
      users_total_points
      status
      created_at
    }
  }
`;

/**
 * __useGetConversionPointsRequestsQuery__
 *
 * To run a query within a React component, call `useGetConversionPointsRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversionPointsRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversionPointsRequestsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetConversionPointsRequestsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetConversionPointsRequestsQuery,
    GetConversionPointsRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetConversionPointsRequestsQuery,
    GetConversionPointsRequestsQueryVariables
  >(GetConversionPointsRequestsDocument, options);
}
export function useGetConversionPointsRequestsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetConversionPointsRequestsQuery,
    GetConversionPointsRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetConversionPointsRequestsQuery,
    GetConversionPointsRequestsQueryVariables
  >(GetConversionPointsRequestsDocument, options);
}
export type GetConversionPointsRequestsQueryHookResult = ReturnType<
  typeof useGetConversionPointsRequestsQuery
>;
export type GetConversionPointsRequestsLazyQueryHookResult = ReturnType<
  typeof useGetConversionPointsRequestsLazyQuery
>;
export type GetConversionPointsRequestsQueryResult =
  ApolloReactCommon.QueryResult<
    GetConversionPointsRequestsQuery,
    GetConversionPointsRequestsQueryVariables
  >;
export const GetDepartmentsDocument = gql`
  query getDepartments($propertyID: ID!) {
    propertyDepartments(propertyID: $propertyID) {
      id
      property_id
      name
      department_heads
    }
  }
`;

/**
 * __useGetDepartmentsQuery__
 *
 * To run a query within a React component, call `useGetDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepartmentsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetDepartmentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetDepartmentsQuery,
    GetDepartmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetDepartmentsQuery,
    GetDepartmentsQueryVariables
  >(GetDepartmentsDocument, options);
}
export function useGetDepartmentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDepartmentsQuery,
    GetDepartmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetDepartmentsQuery,
    GetDepartmentsQueryVariables
  >(GetDepartmentsDocument, options);
}
export type GetDepartmentsQueryHookResult = ReturnType<
  typeof useGetDepartmentsQuery
>;
export type GetDepartmentsLazyQueryHookResult = ReturnType<
  typeof useGetDepartmentsLazyQuery
>;
export type GetDepartmentsQueryResult = ApolloReactCommon.QueryResult<
  GetDepartmentsQuery,
  GetDepartmentsQueryVariables
>;
export const UpsertDepartmentDocument = gql`
  mutation upsertDepartment($input: DepartmentInput!) {
    upsertDepartment(departmentInput: $input)
  }
`;
export type UpsertDepartmentMutationFn = ApolloReactCommon.MutationFunction<
  UpsertDepartmentMutation,
  UpsertDepartmentMutationVariables
>;

/**
 * __useUpsertDepartmentMutation__
 *
 * To run a mutation, you first call `useUpsertDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDepartmentMutation, { data, loading, error }] = useUpsertDepartmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertDepartmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertDepartmentMutation,
    UpsertDepartmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpsertDepartmentMutation,
    UpsertDepartmentMutationVariables
  >(UpsertDepartmentDocument, options);
}
export type UpsertDepartmentMutationHookResult = ReturnType<
  typeof useUpsertDepartmentMutation
>;
export type UpsertDepartmentMutationResult =
  ApolloReactCommon.MutationResult<UpsertDepartmentMutation>;
export type UpsertDepartmentMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpsertDepartmentMutation,
    UpsertDepartmentMutationVariables
  >;
export const AuthorizeNewUserDocument = gql`
  mutation authorizeNewUser($authorizeUserInput: AuthirizeUserInput!) {
    authorizeNewUser(authorizeUserInput: $authorizeUserInput)
  }
`;
export type AuthorizeNewUserMutationFn = ApolloReactCommon.MutationFunction<
  AuthorizeNewUserMutation,
  AuthorizeNewUserMutationVariables
>;

/**
 * __useAuthorizeNewUserMutation__
 *
 * To run a mutation, you first call `useAuthorizeNewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeNewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeNewUserMutation, { data, loading, error }] = useAuthorizeNewUserMutation({
 *   variables: {
 *      authorizeUserInput: // value for 'authorizeUserInput'
 *   },
 * });
 */
export function useAuthorizeNewUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AuthorizeNewUserMutation,
    AuthorizeNewUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AuthorizeNewUserMutation,
    AuthorizeNewUserMutationVariables
  >(AuthorizeNewUserDocument, options);
}
export type AuthorizeNewUserMutationHookResult = ReturnType<
  typeof useAuthorizeNewUserMutation
>;
export type AuthorizeNewUserMutationResult =
  ApolloReactCommon.MutationResult<AuthorizeNewUserMutation>;
export type AuthorizeNewUserMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AuthorizeNewUserMutation,
    AuthorizeNewUserMutationVariables
  >;
export const GetNewUsersDocument = gql`
  query getNewUsers($propertyID: ID!) {
    getNewUsers(propertyID: $propertyID) {
      id
      first_name
      last_name
      email
      property_id
      is_authorized
      created_at
      employee_id
    }
  }
`;

/**
 * __useGetNewUsersQuery__
 *
 * To run a query within a React component, call `useGetNewUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewUsersQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetNewUsersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetNewUsersQuery,
    GetNewUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetNewUsersQuery, GetNewUsersQueryVariables>(
    GetNewUsersDocument,
    options,
  );
}
export function useGetNewUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetNewUsersQuery,
    GetNewUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetNewUsersQuery,
    GetNewUsersQueryVariables
  >(GetNewUsersDocument, options);
}
export type GetNewUsersQueryHookResult = ReturnType<typeof useGetNewUsersQuery>;
export type GetNewUsersLazyQueryHookResult = ReturnType<
  typeof useGetNewUsersLazyQuery
>;
export type GetNewUsersQueryResult = ApolloReactCommon.QueryResult<
  GetNewUsersQuery,
  GetNewUsersQueryVariables
>;
export const SubmitPointsDocument = gql`
  mutation submitPoints($pointSubmissionID: ID!, $approvedPoints: Int!) {
    approvePointsSubmission(
      pointSubmissionID: $pointSubmissionID
      approvedPoints: $approvedPoints
    )
  }
`;
export type SubmitPointsMutationFn = ApolloReactCommon.MutationFunction<
  SubmitPointsMutation,
  SubmitPointsMutationVariables
>;

/**
 * __useSubmitPointsMutation__
 *
 * To run a mutation, you first call `useSubmitPointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPointsMutation, { data, loading, error }] = useSubmitPointsMutation({
 *   variables: {
 *      pointSubmissionID: // value for 'pointSubmissionID'
 *      approvedPoints: // value for 'approvedPoints'
 *   },
 * });
 */
export function useSubmitPointsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SubmitPointsMutation,
    SubmitPointsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SubmitPointsMutation,
    SubmitPointsMutationVariables
  >(SubmitPointsDocument, options);
}
export type SubmitPointsMutationHookResult = ReturnType<
  typeof useSubmitPointsMutation
>;
export type SubmitPointsMutationResult =
  ApolloReactCommon.MutationResult<SubmitPointsMutation>;
export type SubmitPointsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SubmitPointsMutation,
  SubmitPointsMutationVariables
>;
export const DeclinePointsSubmissionDocument = gql`
  mutation declinePointsSubmission(
    $pointSubmissionID: ID!
    $declineReason: String
  ) {
    declinePointsSubmission(
      pointSubmissionID: $pointSubmissionID
      declineReason: $declineReason
    )
  }
`;
export type DeclinePointsSubmissionMutationFn =
  ApolloReactCommon.MutationFunction<
    DeclinePointsSubmissionMutation,
    DeclinePointsSubmissionMutationVariables
  >;

/**
 * __useDeclinePointsSubmissionMutation__
 *
 * To run a mutation, you first call `useDeclinePointsSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclinePointsSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declinePointsSubmissionMutation, { data, loading, error }] = useDeclinePointsSubmissionMutation({
 *   variables: {
 *      pointSubmissionID: // value for 'pointSubmissionID'
 *      declineReason: // value for 'declineReason'
 *   },
 * });
 */
export function useDeclinePointsSubmissionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeclinePointsSubmissionMutation,
    DeclinePointsSubmissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeclinePointsSubmissionMutation,
    DeclinePointsSubmissionMutationVariables
  >(DeclinePointsSubmissionDocument, options);
}
export type DeclinePointsSubmissionMutationHookResult = ReturnType<
  typeof useDeclinePointsSubmissionMutation
>;
export type DeclinePointsSubmissionMutationResult =
  ApolloReactCommon.MutationResult<DeclinePointsSubmissionMutation>;
export type DeclinePointsSubmissionMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeclinePointsSubmissionMutation,
    DeclinePointsSubmissionMutationVariables
  >;
export const RevertApprovedSubmissionDocument = gql`
  mutation revertApprovedSubmission($pointSubmissionID: ID!) {
    revertApprovedSubmission(pointSubmissionID: $pointSubmissionID)
  }
`;
export type RevertApprovedSubmissionMutationFn =
  ApolloReactCommon.MutationFunction<
    RevertApprovedSubmissionMutation,
    RevertApprovedSubmissionMutationVariables
  >;

/**
 * __useRevertApprovedSubmissionMutation__
 *
 * To run a mutation, you first call `useRevertApprovedSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertApprovedSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertApprovedSubmissionMutation, { data, loading, error }] = useRevertApprovedSubmissionMutation({
 *   variables: {
 *      pointSubmissionID: // value for 'pointSubmissionID'
 *   },
 * });
 */
export function useRevertApprovedSubmissionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RevertApprovedSubmissionMutation,
    RevertApprovedSubmissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RevertApprovedSubmissionMutation,
    RevertApprovedSubmissionMutationVariables
  >(RevertApprovedSubmissionDocument, options);
}
export type RevertApprovedSubmissionMutationHookResult = ReturnType<
  typeof useRevertApprovedSubmissionMutation
>;
export type RevertApprovedSubmissionMutationResult =
  ApolloReactCommon.MutationResult<RevertApprovedSubmissionMutation>;
export type RevertApprovedSubmissionMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    RevertApprovedSubmissionMutation,
    RevertApprovedSubmissionMutationVariables
  >;
export const RevertDeclinedSubmissionDocument = gql`
  mutation revertDeclinedSubmission($pointSubmissionID: ID!) {
    revertDeclinedSubmission(pointSubmissionID: $pointSubmissionID)
  }
`;
export type RevertDeclinedSubmissionMutationFn =
  ApolloReactCommon.MutationFunction<
    RevertDeclinedSubmissionMutation,
    RevertDeclinedSubmissionMutationVariables
  >;

/**
 * __useRevertDeclinedSubmissionMutation__
 *
 * To run a mutation, you first call `useRevertDeclinedSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertDeclinedSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertDeclinedSubmissionMutation, { data, loading, error }] = useRevertDeclinedSubmissionMutation({
 *   variables: {
 *      pointSubmissionID: // value for 'pointSubmissionID'
 *   },
 * });
 */
export function useRevertDeclinedSubmissionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RevertDeclinedSubmissionMutation,
    RevertDeclinedSubmissionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RevertDeclinedSubmissionMutation,
    RevertDeclinedSubmissionMutationVariables
  >(RevertDeclinedSubmissionDocument, options);
}
export type RevertDeclinedSubmissionMutationHookResult = ReturnType<
  typeof useRevertDeclinedSubmissionMutation
>;
export type RevertDeclinedSubmissionMutationResult =
  ApolloReactCommon.MutationResult<RevertDeclinedSubmissionMutation>;
export type RevertDeclinedSubmissionMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    RevertDeclinedSubmissionMutation,
    RevertDeclinedSubmissionMutationVariables
  >;
export const GetPointsSubmissionsDocument = gql`
  query getPointsSubmissions($propertyID: ID!) {
    propertyPointsSubmissions(propertyID: $propertyID) {
      id
      user_id
      amount
      reference_receipt
      status
      challenge_id
      sub_challenge_id
      status_changed_by
      image_url
      challenge_name
      sub_challenge_name
      created_at
      challenge_terms
    }
  }
`;

/**
 * __useGetPointsSubmissionsQuery__
 *
 * To run a query within a React component, call `useGetPointsSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPointsSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPointsSubmissionsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetPointsSubmissionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPointsSubmissionsQuery,
    GetPointsSubmissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPointsSubmissionsQuery,
    GetPointsSubmissionsQueryVariables
  >(GetPointsSubmissionsDocument, options);
}
export function useGetPointsSubmissionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPointsSubmissionsQuery,
    GetPointsSubmissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPointsSubmissionsQuery,
    GetPointsSubmissionsQueryVariables
  >(GetPointsSubmissionsDocument, options);
}
export type GetPointsSubmissionsQueryHookResult = ReturnType<
  typeof useGetPointsSubmissionsQuery
>;
export type GetPointsSubmissionsLazyQueryHookResult = ReturnType<
  typeof useGetPointsSubmissionsLazyQuery
>;
export type GetPointsSubmissionsQueryResult = ApolloReactCommon.QueryResult<
  GetPointsSubmissionsQuery,
  GetPointsSubmissionsQueryVariables
>;
export const UserProfileDocument = gql`
  mutation userProfile($userID: ID!, $profileUrl: String!) {
    updateUserProfile(userID: $userID, profileUrl: $profileUrl)
  }
`;
export type UserProfileMutationFn = ApolloReactCommon.MutationFunction<
  UserProfileMutation,
  UserProfileMutationVariables
>;

/**
 * __useUserProfileMutation__
 *
 * To run a mutation, you first call `useUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userProfileMutation, { data, loading, error }] = useUserProfileMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      profileUrl: // value for 'profileUrl'
 *   },
 * });
 */
export function useUserProfileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserProfileMutation,
    UserProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UserProfileMutation,
    UserProfileMutationVariables
  >(UserProfileDocument, options);
}
export type UserProfileMutationHookResult = ReturnType<
  typeof useUserProfileMutation
>;
export type UserProfileMutationResult =
  ApolloReactCommon.MutationResult<UserProfileMutation>;
export type UserProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserProfileMutation,
  UserProfileMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($updateUserInput: UpdateUserInput!) {
    updateUserData(updateUserInput: $updateUserInput)
  }
`;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      updateUserInput: // value for 'updateUserInput'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const GetUploadSignedUrlDocument = gql`
  query getUploadSignedURL($fileType: String!, $extension: String!) {
    getUploadSignedURL(fileType: $fileType, extension: $extension) {
      url
      presigned_upload_url
    }
  }
`;

/**
 * __useGetUploadSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetUploadSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadSignedUrlQuery({
 *   variables: {
 *      fileType: // value for 'fileType'
 *      extension: // value for 'extension'
 *   },
 * });
 */
export function useGetUploadSignedUrlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetUploadSignedUrlQuery,
    GetUploadSignedUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetUploadSignedUrlQuery,
    GetUploadSignedUrlQueryVariables
  >(GetUploadSignedUrlDocument, options);
}
export function useGetUploadSignedUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUploadSignedUrlQuery,
    GetUploadSignedUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetUploadSignedUrlQuery,
    GetUploadSignedUrlQueryVariables
  >(GetUploadSignedUrlDocument, options);
}
export type GetUploadSignedUrlQueryHookResult = ReturnType<
  typeof useGetUploadSignedUrlQuery
>;
export type GetUploadSignedUrlLazyQueryHookResult = ReturnType<
  typeof useGetUploadSignedUrlLazyQuery
>;
export type GetUploadSignedUrlQueryResult = ApolloReactCommon.QueryResult<
  GetUploadSignedUrlQuery,
  GetUploadSignedUrlQueryVariables
>;
export const GetPointsSubmissionsWithDateRangeDocument = gql`
  query getPointsSubmissionsWithDateRange(
    $propertyID: ID!
    $startDate: String
    $endDate: String
  ) {
    propertyPointsSubmissionsWithDateRange(
      propertyID: $propertyID
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      user_id
      amount
      reference_receipt
      status
      challenge_id
      sub_challenge_id
      status_changed_by
      image_url
      challenge_name
      sub_challenge_name
      created_at
      updated_by
      approved_points
    }
  }
`;

/**
 * __useGetPointsSubmissionsWithDateRangeQuery__
 *
 * To run a query within a React component, call `useGetPointsSubmissionsWithDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPointsSubmissionsWithDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPointsSubmissionsWithDateRangeQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetPointsSubmissionsWithDateRangeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPointsSubmissionsWithDateRangeQuery,
    GetPointsSubmissionsWithDateRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPointsSubmissionsWithDateRangeQuery,
    GetPointsSubmissionsWithDateRangeQueryVariables
  >(GetPointsSubmissionsWithDateRangeDocument, options);
}
export function useGetPointsSubmissionsWithDateRangeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPointsSubmissionsWithDateRangeQuery,
    GetPointsSubmissionsWithDateRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPointsSubmissionsWithDateRangeQuery,
    GetPointsSubmissionsWithDateRangeQueryVariables
  >(GetPointsSubmissionsWithDateRangeDocument, options);
}
export type GetPointsSubmissionsWithDateRangeQueryHookResult = ReturnType<
  typeof useGetPointsSubmissionsWithDateRangeQuery
>;
export type GetPointsSubmissionsWithDateRangeLazyQueryHookResult = ReturnType<
  typeof useGetPointsSubmissionsWithDateRangeLazyQuery
>;
export type GetPointsSubmissionsWithDateRangeQueryResult =
  ApolloReactCommon.QueryResult<
    GetPointsSubmissionsWithDateRangeQuery,
    GetPointsSubmissionsWithDateRangeQueryVariables
  >;
export const GetAllChallengesDocument = gql`
  query getAllChallenges($propertyID: ID!) {
    allChallenges(propertyID: $propertyID) {
      id
      property_id
      department_id
      name
      description
      points
      status
      doc_urls
    }
  }
`;

/**
 * __useGetAllChallengesQuery__
 *
 * To run a query within a React component, call `useGetAllChallengesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllChallengesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllChallengesQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetAllChallengesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAllChallengesQuery,
    GetAllChallengesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetAllChallengesQuery,
    GetAllChallengesQueryVariables
  >(GetAllChallengesDocument, options);
}
export function useGetAllChallengesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllChallengesQuery,
    GetAllChallengesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetAllChallengesQuery,
    GetAllChallengesQueryVariables
  >(GetAllChallengesDocument, options);
}
export type GetAllChallengesQueryHookResult = ReturnType<
  typeof useGetAllChallengesQuery
>;
export type GetAllChallengesLazyQueryHookResult = ReturnType<
  typeof useGetAllChallengesLazyQuery
>;
export type GetAllChallengesQueryResult = ApolloReactCommon.QueryResult<
  GetAllChallengesQuery,
  GetAllChallengesQueryVariables
>;
export const DeleteUserDocument = gql`
  mutation deleteUser($userID: ID!) {
    deleteUser(userID: $userID)
  }
`;
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >(DeleteUserDocument, options);
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult =
  ApolloReactCommon.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const GetPropertyUsersDocument = gql`
  query getPropertyUsers($propertyID: ID!) {
    getPropertyUsers(propertyID: $propertyID) {
      id
      first_name
      last_name
      email
      property_id
      total_points
      created_at
      is_authorized
      is_manager
      is_supervisor
      is_admin
      reports_to
      deleted_at
      department_id
      is_department_head
      employee_id
    }
  }
`;

/**
 * __useGetPropertyUsersQuery__
 *
 * To run a query within a React component, call `useGetPropertyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyUsersQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetPropertyUsersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPropertyUsersQuery,
    GetPropertyUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPropertyUsersQuery,
    GetPropertyUsersQueryVariables
  >(GetPropertyUsersDocument, options);
}
export function useGetPropertyUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPropertyUsersQuery,
    GetPropertyUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPropertyUsersQuery,
    GetPropertyUsersQueryVariables
  >(GetPropertyUsersDocument, options);
}
export type GetPropertyUsersQueryHookResult = ReturnType<
  typeof useGetPropertyUsersQuery
>;
export type GetPropertyUsersLazyQueryHookResult = ReturnType<
  typeof useGetPropertyUsersLazyQuery
>;
export type GetPropertyUsersQueryResult = ApolloReactCommon.QueryResult<
  GetPropertyUsersQuery,
  GetPropertyUsersQueryVariables
>;
