/* eslint-disable no-unused-vars */
export enum NotifierType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
}
/* eslint-enable no-unused-vars */
export type User = {
  id: String;
};

export type Noop = () => void;
/* eslint-disable no-unused-vars */
export type OpenSnackbarFunction = (
  notifierProp: NotifierProps,
  variant: NotifierType
) => void;
/* eslint-disable no-unused-vars */
export interface NotifierProps {
  message: string;
  messageTitle?: string;
  customIcon?: any;
  actionButtonText?: string;
  onActionButtonClick?: () => void;
}
