import {
  PointsSubmission,
  useGetAllChallengesQuery,
  useGetPointsSubmissionsWithDateRangeQuery,
  useGetPropertyUsersQuery
} from "../../generated/graphql";
import {useAuthProvider} from "../../core/authContext";
import {useEffect, useState} from "react";
import _ from "lodash";
import JSZip from "jszip";
import { saveAs } from 'file-saver';

const useReports = () => {
  const date = new Date();
  const [dateRange, setDateRange] = useState({
    startDate: new Date(date.getFullYear(), date.getMonth(), 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0)
  });
  const [entries, setEntries] = useState<Array<any>>([]);
  const [propertyUsers, setPropertyUsers] = useState<any>({});
  const [challenges, setChallenges] = useState<any>({});
  const [downloadingFiles, setDownloadingFiles] = useState<boolean>(false);
  const { getUser } = useAuthProvider();
  const user = getUser();

  const { data: usersRes, loading: loadingUsers } = useGetPropertyUsersQuery({
    fetchPolicy: 'network-only',
    variables: {
      propertyID: user?.property_id,
    },
    skip: !user?.property_id,
  });

  useEffect(() => {
    if (!loadingUsers && usersRes?.getPropertyUsers) {
      setPropertyUsers(_.keyBy(usersRes?.getPropertyUsers, "id"));
    }
  }, [usersRes, loadingUsers]);

  const {
    data: challengesRes,
    loading: loadingChallenges
  } = useGetAllChallengesQuery({
    fetchPolicy: 'network-only',
    variables: {
      propertyID: user?.property_id,
    },
    skip: !user?.property_id,
  });

  useEffect(() => {
    if (!loadingChallenges && challengesRes?.allChallenges) {
      setChallenges(_.keyBy(challengesRes?.allChallenges, "id"));
    }
  }, [challengesRes, loadingChallenges]);

  const { data, loading, refetch } = useGetPointsSubmissionsWithDateRangeQuery({
    fetchPolicy: 'network-only',
    variables: {
      propertyID: user?.property_id,
      startDate: dateRange.startDate.toISOString(),
      endDate: dateRange.endDate.toISOString(),
    },
    skip: !user?.property_id,
  });

  useEffect(()=>{
    if (
      !loading &&
      data?.propertyPointsSubmissionsWithDateRange &&
      challenges &&
      propertyUsers
    ) {
      setEntries(
        data?.propertyPointsSubmissionsWithDateRange?.sort(
          (a:PointsSubmission | null, b:PointsSubmission | null) => {
            if (b?.created_at && a?.created_at) {
              return new Date(b?.created_at).valueOf() -
                new Date(a?.created_at).valueOf();
            }
            return 0;
          }).map(submission => {
          const user = submission && propertyUsers[submission.user_id];
          const challenge =
            submission &&
            submission.challenge_id &&
            challenges[submission.challenge_id];
          return {
            id: submission?.id,
            employee_name:
              submission ?
                `${user?.first_name} ${user?.last_name}` :
                "",
            challenge_name: submission &&
              submission.challenge_id ?
              challenge?.name :
              "",
            challenge_terms: submission && submission.challenge_id ?
              challenge?.doc_urls :
              [],
            created_at: submission?.created_at,
            amount: submission?.amount,
            reference_receipt: submission?.reference_receipt,
            approved_by: submission && submission.updated_by ?
              `
              ${propertyUsers[submission.updated_by].first_name}
              ${propertyUsers[submission.updated_by].last_name}
              ` :
              "",
            approved_points: submission?.approved_points,
            status: submission?.status,
            submitted_documents: submission?.image_url
          };
        })
      );
    }
  }, [data, loading, challenges, propertyUsers]);

  const saveZip = () => {
    if(!data || !data?.propertyPointsSubmissionsWithDateRange) return;

    const zip = new JSZip();
    const folder = zip.folder("files");
    setDownloadingFiles(true);
    data?.propertyPointsSubmissionsWithDateRange.forEach((submission) => {
      submission?.image_url?.forEach((url) =>{
        if (url) {
          const blobPromise = fetch(url).then((r) => {
            if (r.status === 200) return r.blob();
            return Promise.reject(new Error(r.statusText));
          });
          const name = url.substring(url.lastIndexOf("/") + 1);
          folder?.file(name, blobPromise);
        }
      });
    });

    zip.generateAsync({ type: "blob" }).then((blob) => {
      saveAs(
        blob,
        `${dateRange.startDate.toString().replace(" ", "_")}
        _
        ${dateRange.endDate.toString().replace(" ", "_")}.zip`
      );
      setDownloadingFiles(false);
    }
    );

  };

  const changeDateRange = (startDate: Date, endDate: Date) => {
    setDateRange({
      ...dateRange,
      startDate: startDate || dateRange.startDate,
      endDate: endDate || dateRange.endDate,
    });
    refetch({
      propertyID: user?.propertyID,
      startDate: dateRange.startDate.toString(),
      endDate: dateRange.endDate.toString(),
    });
  };

  return {
    loading: loading || loadingUsers || loadingChallenges || downloadingFiles,
    dateRange,
    entries,
    changeDateRange,
    refetch,
    saveZip,
  };
};

export default useReports;
