import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Auth, Cache } from 'aws-amplify';
import useUserInfo from '../../core/useUserInfo';

const useLogin = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isLoadingUserInfo] = useUserInfo();

  const onLogin = useCallback(
    async (data: Record<string, string>) => {
      setLoading(true);
      try {
        await Auth.signIn({
          username: data.username,
          password: data.password,
        });

        const session = await Auth.currentSession();
        Cache.setItem('session', session);
        setLoading(false);
        navigate('/');
      } catch (err: any) {
        setLoading(false);
        console.log(err);
      }
    },
    [navigate, setLoading],
  );

  return {
    register,
    handleSubmit,
    errors,
    onLogin,
    loading: isLoadingUserInfo || loading,
  };
};

export default useLogin;
