import PrivateRoute from '../core/privateRoute';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
// import Layout from '../Containers/layout';
import Dashboard from './Dashboard';
import React from 'react';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <Route path="/*" element={<Dashboard />} />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;
