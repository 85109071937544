import {useGetUploadSignedUrlLazyQuery} from "../generated/graphql";
import React from "react";

const useUpload = () => {
  const [getUploadSignedUrl] = useGetUploadSignedUrlLazyQuery({
    fetchPolicy: 'network-only',
  });

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null) {

      const localFile = e.target.files[0];
      const filename = localFile.name;
      const fileType = localFile.type;
      const extension = filename.split('.')[1];
      try {
        const { data: awsData } = await getUploadSignedUrl({
          variables: {
            fileType,
            extension,
          },
        });

        const {
          getUploadSignedURL: { presigned_upload_url, url: uploadedImageURL },
        } = awsData as any;

        const picture = await fetch(URL.createObjectURL(e.target.files[0]));
        const pictureBlob = await picture.blob();
        const file = new File([pictureBlob], filename);

        await fetch(presigned_upload_url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': fileType,
            'Access-Control-Allow-Origin': '*',
          },
        });
        return uploadedImageURL;
      } catch (error) {
        console.log(error);
      }
    }
    return null;
  };
  return {
    getUploadSignedUrl,
    uploadFile
  };
};

export default useUpload;
