import React from 'react';
import { useAuthProvider } from '../../core/authContext';
import {
  useGetNewUsersQuery,
  useDeleteUserMutation,
  useGetPropertyDepartmentsQuery, useGetPropertyUsersQuery,
} from '../../generated/graphql';
import swal from 'sweetalert';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';


const useUsers = () => {
  const { getUser } = useAuthProvider();
  const [open, setOpen] = React.useState(false);
  const [openDeclineModal, setOpenDeclineModal] = React.useState(false);
  const [userID, setUserID] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState({} as any);

  //Handle Approved Popup
  const handleClickOpen = (id: string) => {
    setSelectedUser({
      user_id: id,
    });
    setUserID(id);
    setOpen(true);
  };

  const handleClickOpenDeclineModal = (id: string) => {
    setUserID(id);
    setOpenDeclineModal(true);
  };

  const handleCloseDeclineModal = () => {
    setOpenDeclineModal(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const user = getUser();
  const { data, loading, refetch } = useGetNewUsersQuery({
    fetchPolicy: 'network-only',
    variables: {
      propertyID: user?.property_id,
    },
    skip: !user?.property_id,
  });

  const {
    data: propertyUsers,
    loading: loadingPropertyUsers,
    refetch: refetchPropertyUsers
  } = useGetPropertyUsersQuery({
    fetchPolicy: 'network-only',
    variables: {
      propertyID: user?.property_id,
    },
    skip: !user?.property_id,
  });

  const onClose = () => {
    setOpen(false);
    refetch();
    refetchPropertyUsers();
  };

  const [deleteUser, { loading: loadingDeclineUser }] = useDeleteUserMutation();

  const onDeclineUser = async () => {
    try {
      const res = await deleteUser({
        variables: {
          userID: userID,
        },
      });
      if (res.data?.deleteUser) {
        refetch?.();
        openSnackbar(
          { message: 'Declined Successfully' },
          NotifierType.Success,
        );
        handleCloseDeclineModal();
      } else {
        openSnackbar(
          { message: 'Unable to decline this user, contact support!' },
          NotifierType.Error,
        );
        await swal({
          title: 'Error',
          text: 'Unable to decline this user, contact support!',
          icon: 'error',
        });
      }
    } catch (err: any) {
      console.log(err);
      openSnackbar({ message: err?.toString() || '' }, NotifierType.Error);
    }
  };

  const { data: deptData, loading: deptLoading } =
    useGetPropertyDepartmentsQuery({
      fetchPolicy: 'network-only',
      variables: {
        propertyID: user?.property_id,
      },
      skip: !user?.property_id,
    });


  return {
    users: data?.getNewUsers,
    propertyUsers: propertyUsers?.getPropertyUsers,
    loading:
      loading ||
      loadingDeclineUser ||
      deptLoading ||
      loadingPropertyUsers,
    departments: deptData?.propertyDepartments,
    handleClickOpen,
    handleClose,
    open,
    refetch,
    onDeclineUser,
    handleCloseDeclineModal,
    handleClickOpenDeclineModal,
    openDeclineModal,
    userID,
    onClose,
    selectedUser
  };
};

export default useUsers;
