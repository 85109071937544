import {
  Button,
  Checkbox, DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormGroup, InputLabel, MenuItem, Select,
  Stack, Typography
} from "@mui/material";
import {Department, User} from "../../generated/graphql";
import React, {useMemo} from "react";
import useUserForm, {AuthorizeForm} from "./userFormHook";

interface props {
  departments: (Department|null)[];
  handleClose: ()=>void;
  propertyUsers: (User|null)[];
  selectedUser: AuthorizeForm,
  onClose: Function;
}
const UserForm = ({
  departments,
  handleClose,
  propertyUsers,
  selectedUser,
  onClose
}: props) => {

  const {
    loading,
    register,
    handleSubmit,
    errors,
    onSubmit,
    selectedDeptID,
    selectedUserIsDeptHead,
    getValues,
    setValue,
  } = useUserForm({
    defaultUser: selectedUser,
    onClose: onClose
  });

  const getReportingUsers = (
    users: any,
    selectedDeptID: string,
    isDeptHead: boolean,
    userID: string) => {
    users = users?.filter(
      (user: User | null) =>
        user?.is_department_head &&
        user.id !== userID && 
        user?.is_authorized &&
        !user.deleted_at,
    );
    if (selectedDeptID && !isDeptHead) {
      const sameDepartmentUsers = users?.filter(
        (user: User | null) => user?.department_id === selectedDeptID,
      );
      if (sameDepartmentUsers.length > 0) {
        users = sameDepartmentUsers;
        if (!getValues('reports_to') || users.findIndex((user: User) => user.id === getValues('reports_to')) < 0) {
          setValue('reports_to', sameDepartmentUsers[0].id);
        }
      }
    }
    return users;
  };

  const reportsToUsers = useMemo(
    () => getReportingUsers(
      propertyUsers,
      selectedDeptID,
      selectedUserIsDeptHead,
      selectedUser.user_id),
    [
      propertyUsers,
      selectedDeptID,
      selectedUserIsDeptHead,
      selectedUser
    ]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <DialogContentText></DialogContentText>
        <Stack spacing={1}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  {...register('is_admin')}
                  checked={getValues('is_admin')}
                />
              }
              label="Admin"
            />
            <FormControlLabel
              id="Manager"
              control={
                <Checkbox
                  {...register('is_manager')}
                  checked={getValues('is_manager')}
                />
              }
              label="Manager"
            />
            <FormControlLabel
              control={
                <Checkbox
                  {...register('is_supervisor')}
                  checked={getValues('is_supervisor')}
                />
              }
              label="Supervisor"
            />
            <FormControlLabel
              control={
                <Checkbox
                  {...register('is_department_head')}
                  checked={getValues('is_department_head')}
                />
              }
              label="Department Head"
            />
          </FormGroup>
          <FormControl fullWidth>
            <InputLabel id="dept-simple-select-label">
              Department
            </InputLabel>
            <Select
              {...register('department_id')}
              labelId="dept-simple-select-label"
              id="dept-simple-select"
              label="department"
              disabled={false}
              value={getValues('department_id') ?? ""}
            >
              {departments?.map((department: Department | null) => (
                <MenuItem
                  key={department?.id || ''}
                  value={department?.id || ''}
                  id={department?.id || ''}
                >
                  {department?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Reports to
            </InputLabel>
            <Select
              {...register('reports_to', { required: true })}
              labelId="demo-simple-select-label"
              id="reports_to"
              label="Reports to"
              disabled={false}
              value={getValues('reports_to') ?? ""}
            >
              {reportsToUsers?.map(
                (reportingUser: User | null) => (
                  <MenuItem
                    key={reportingUser?.id}
                    value={reportingUser?.id}
                  >
                    {reportingUser?.first_name}{' '}
                    {reportingUser?.last_name}
                  </MenuItem>
                ),
              )}
            </Select>
            {errors?.reports_to &&
              <Typography sx={{color: 'red'}}>
                {errors?.reports_to?.message}
              </Typography>}
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
        >
          {loading ? "saving..." : "Save"}
        </Button>
      </DialogActions>
    </form>
  );
};

export default UserForm;
