import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl, IconButton, InputAdornment,
  InputLabel, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import {ChallengeRewardType} from "../../generated/graphql";
import React from "react";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
  openChallenge: boolean;
  handleChallengeClose: ()=>void;
  /* eslint-disable no-unused-vars */
  onSubmit: any;
  registerChallenge: any;
  /* eslint-disable no-unused-vars */
  handleSubmitChallenge: any;
  deptData: any;
  challengeRewardType: ChallengeRewardType;
  handleUpload: any;
  challengeTermsAndConditions: any;
  removeTermsFile: any;
  loader: boolean;
}
const ChallengeForm = ({
  openChallenge,
  handleChallengeClose,
  onSubmit,
  registerChallenge,
  handleSubmitChallenge,
  deptData,
  challengeRewardType,
  handleUpload,
  challengeTermsAndConditions,
  removeTermsFile,
  loader
}: Props) => {
  return <Dialog open={openChallenge} onClose={handleChallengeClose}>
    <form onSubmit={handleSubmitChallenge(onSubmit)}>
      <DialogTitle>Challenge</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter your challenge details. We will send updates
          occasionally.
        </DialogContentText>
        <TextField
          margin="normal"
          fullWidth
          id="title"
          label="Title"
          {...registerChallenge('title', { required: true })}
          autoComplete="title"
          autoFocus
        />
        <FormControl fullWidth>
          <InputLabel>
            Reward Mode
          </InputLabel>
          <Select
            label="Reward Type"
            {...registerChallenge('reward_type', { required: true })}
          >
            <MenuItem
              key={ChallengeRewardType.Direct}
              value={ChallengeRewardType.Direct}>
              {ChallengeRewardType.Direct}
            </MenuItem>
            <MenuItem
              key={ChallengeRewardType.Percent}
              value={ChallengeRewardType.Percent}>
              {ChallengeRewardType.Percent}
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          margin="normal"
          fullWidth
          label="Points"
          type="text"
          id="points"
          InputProps={{
            endAdornment: challengeRewardType === ChallengeRewardType.Percent ?
              <InputAdornment position="start">%</InputAdornment> :
              <InputAdornment position="start">AED</InputAdornment>,
          }}
          {...registerChallenge('points', { required: true })}
        />
        <TextField
          margin="normal"
          fullWidth
          label="Max Points"
          type="text"
          id="max_reward_points"
          {...registerChallenge('max_reward_points', { required: true })}
        />
        <TextField
          margin="normal"
          fullWidth
          id="description"
          label="Description"
          {...registerChallenge('description', { required: true })}
          autoComplete="description"
          autoFocus
        />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Department
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="department"
            {...registerChallenge('department_id', { required: true })}
          >
            {deptData?.map((departments: any) => (
              <MenuItem key={departments.id} value={departments.id}>
                {departments.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <List>
            {
              challengeTermsAndConditions &&
              challengeTermsAndConditions.map(
                (challengeTermsAndCondition: string) =>
                <ListItem
                  disablePadding
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <DeleteIcon onClick={
                        () => removeTermsFile(challengeTermsAndCondition)
                      }/>
                    </IconButton>
                  }>
                  <ListItemButton>
                    <ListItemIcon>
                      {
                        challengeTermsAndCondition?.endsWith("pdf") ?
                          <PictureAsPdfIcon/> :
                          <ImageIcon/>
                      }
                    </ListItemIcon>
                    <Link href={challengeTermsAndCondition}
                      color="inherit"
                      target="_blank">
                      <ListItemText
                        primary={
                          `File ${
                            challengeTermsAndCondition?.split("/")[
                              challengeTermsAndCondition?.split("/").length - 1
                            ]
                          }`
                        }
                      />
                    </Link>
                  </ListItemButton>
                </ListItem>
              )
            }
          </List>
        </FormControl>
        <FormControl fullWidth sx={{mt: 2}}>
          <input
            type="file"
            accept=".png , .jpg , .jpeg, .pdf"
            onChange={handleUpload}
            disabled={loader}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleChallengeClose}>Cancel</Button>
        <Button
          disabled={loader}
          color="primary"
          variant="contained"
          type="submit">
          Save
        </Button>
      </DialogActions>
    </form>
  </Dialog>;
};

export default ChallengeForm;
