import React from 'react';
import { useForm } from 'react-hook-form';
import { useAuthProvider } from '../../core/authContext';
import { Auth } from 'aws-amplify';
import swal from 'sweetalert';
import {
  useUserProfileMutation,
  useUpdateUserMutation,
} from '../../generated/graphql';
import useUpload from "../../utils/upload";

interface ProfileForm {
  first_name: string;
  last_name: string;
  phone: string;
}

interface ChangePasswordForm {
  oldPassword: string;
  newPassword: string;
}

const useProfile = () => {

  const [updateUserProfile, { loading: loadingProfileImage }] =
    useUserProfileMutation();
  const [updateUserData, { loading: loadingUserData }] =
    useUpdateUserMutation();

  const [open, setOpen] = React.useState(false);

  const [defaultImg, setImage] = React.useState<any>('assets/avatar.jpg');
  const {uploadFile} = useUpload();

  // handle Image src
  const handleImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null) {
      await setImage(URL.createObjectURL(e.target.files[0]));
      const uploadedImageURL = await uploadFile(e);
      if (uploadedImageURL) {
        await updateUserProfile({
          variables: {
            userID: user?.id,
            profileUrl: uploadedImageURL,
          },
        });
      }
    }
  };

  const changePasswordOpen = () => {
    setOpen(true);
  };

  const changePasswordClose = () => {
    setOpen(false);
  };

  const { getUser } = useAuthProvider();
  const user = getUser();
  const {
    register: registerProfile,
    handleSubmit: handleSubmitProfile,
    reset: resetProfile,
    formState: { errors: errorsProfile, isDirty },
  } = useForm<ProfileForm>({
    defaultValues: {
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      phone: user.phone || '',
    },
  });

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    reset: resetPassword,
    formState: { errors: errorsPassword },
  } = useForm<ChangePasswordForm>();

  // Update Profile
  const onSubmitProfile = async (data: ProfileForm) => {
    // console.log(data);
    // console.log(userProfileImg);
    try {
      await updateUserData({
        variables: {
          updateUserInput: {
            user_id: user.id,
            first_name: data?.first_name,
            last_name: data?.last_name,
            phone: data?.phone,
          },
        },
      });
      swal({
        title: 'Thank you!',
        text: 'Profile Updated Successfully',
        icon: 'success',
      });
    } catch (err) {
      console.log(err);
    }
    resetProfile();
  };

  //Change Password
  const onSubmitChangePassword = async (data: any) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, data.oldPassword, data.newPassword);
      })
      .then(() => {
        setOpen(false);
        swal('Password Changed Successfully!', '', 'success');
      })
      .catch((err) => console.log(err));
  };

  return {
    registerProfile,
    handleSubmitProfile,
    onSubmitProfile,
    resetProfile,
    handleImage,
    loadingProfileImage,
    loadingUserData,
    defaultImg,
    errorsProfile,
    isDirty,
    registerPassword,
    handleSubmitPassword,
    onSubmitChangePassword,
    resetPassword,
    errorsPassword,
    changePasswordOpen,
    changePasswordClose,
    open,
    userEmail: user.email,
    userProfileUrl: user.profile_url,
  };
};

export default useProfile;
