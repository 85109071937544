import React from 'react';
import {
  Grid,
  TextField,
  Card,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  InputAdornment,
  IconButton,
} from '@mui/material';
import Loader from '../../components/Loader';
import useProfile from './profile-hook';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Profile = () => {
  const {
    registerProfile,
    handleSubmitProfile,
    onSubmitProfile,
    registerPassword,
    handleImage,
    defaultImg,
    isDirty,
    handleSubmitPassword,
    onSubmitChangePassword,
    errorsPassword,
    changePasswordOpen,
    changePasswordClose,
    open,
    userEmail,
    userProfileUrl,
  } = useProfile();

  const [showPassword, setShowPassword] = React.useState(false);
  const [Loading] = React.useState<boolean>(true);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      <Card sx={{ p: 3 }}>
        {Loading ? (
          <form onSubmit={handleSubmitProfile(onSubmitProfile)}>
            <Grid container spacing={3} mt={1}>
              <Grid md={4} item>
                {/* profile pic */}
                <Card sx={{ py: 2, px: 3 }}>
                  <Box
                    component={'img'}
                    src={userProfileUrl ? userProfileUrl : defaultImg}
                    alt="profile"
                    sx={{ maxWidth: '100%', height: 'auto' }}
                  />
                  <input
                    type="file"
                    accept=".png , .jpg , .jpeg"
                    onChange={handleImage}
                  />
                </Card>
              </Grid>

              <Grid md={8} item>
                <Grid container spacing={2}>
                  {/* Full name */}
                  <Grid md={6} item>
                    <TextField
                      fullWidth
                      {...registerProfile('first_name', {
                        required: ' Name is required!',
                      })}
                      id="outlined-name"
                      label="First Name"
                    />
                  </Grid>
                  <Grid md={6} item>
                    <TextField
                      fullWidth
                      {...registerProfile('last_name', {
                        required: ' Name is required!',
                      })}
                      id="outlined-name"
                      label="Last Name"
                    />
                  </Grid>

                  {/*email address  */}
                  <Grid md={6} item>
                    <TextField
                      defaultValue={userEmail}
                      id="outlined-basic"
                      fullWidth
                      type="email"
                      label="Email ID"
                      variant="outlined"
                      disabled
                    />
                  </Grid>

                  {/* contact number */}
                  <Grid md={6} item>
                    <TextField
                      fullWidth
                      {...registerProfile('phone', {
                        required: 'Contact Number is required!',
                        maxLength: 12,
                      })}
                      id="outlined-number"
                      label="Contact No."
                      type="number"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                {/* submit */}
                <Grid container spacing={2} justifyContent="center" my={2}>
                  <Stack direction="row" spacing={2}>
                    {isDirty ? (
                      <Button variant="contained" type="submit">
                        &nbsp; Update Profile &nbsp;
                      </Button>
                    ) : (
                      <Button variant="contained" type="submit" disabled>
                        &nbsp; Update Profile &nbsp;
                      </Button>
                    )}
                    <Button variant="contained" onClick={changePasswordOpen}>
                      &nbsp; Change Password &nbsp;
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </form>
        ) : (
          <Loader />
        )}
      </Card>
      <Dialog open={open} onClose={changePasswordClose}>
        <form onSubmit={handleSubmitPassword(onSubmitChangePassword)}>
          <DialogTitle>Change Password</DialogTitle>
          <DialogContent>
            <TextField
              type={showPassword ? 'text' : 'password'}
              margin="normal"
              id="outlined-basic"
              fullWidth
              {...registerPassword('oldPassword', {
                required: 'old password is required!',
              })}
              error={errorsPassword.oldPassword && true}
              helperText={errorsPassword.oldPassword?.message}
              label="Old Password"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type={showPassword ? 'text' : 'password'}
              margin="normal"
              id="outlined-basic"
              fullWidth
              {...registerPassword('newPassword', {
                required: 'New password is required!',
              })}
              error={errorsPassword.newPassword && true}
              helperText={errorsPassword.newPassword?.message}
              label="New Password"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={changePasswordClose}>Cancel</Button>
            <Button type="submit" variant="contained">
              Change
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default Profile;
