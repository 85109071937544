import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthProvider } from '../../core/authContext';
import {
  useGetPointsSubmissionsQuery,
  useGetPropertyUsersQuery,
  useSubmitPointsMutation,
  useDeclinePointsSubmissionMutation,
  useRevertApprovedSubmissionMutation
} from '../../generated/graphql';

import swal from 'sweetalert';
import { useMemo } from 'react';
import _ from 'lodash';

interface PointsApproved {
  pointSubmissionID: string;
  approvedPoints: number;
}

interface PointsDecline {
  reason: string;
}
const usePointsSubmission = () => {
  const [approvePointsSubmission, { loading: loadingPointsApproved }] =
    useSubmitPointsMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<PointsApproved>();

  const {
    register: declineRegister,
    handleSubmit: handleDeclineSubmit,
    reset: resetDeclineForm
  } = useForm<PointsDecline>();

  const { getUser } = useAuthProvider();
  const user = getUser();

  const { data: usersData, loading: loadingUsers } = useGetPropertyUsersQuery({
    fetchPolicy: 'network-only',
    variables: {
      propertyID: user?.property_id,
    },
    skip: !user?.property_id,
  });

  const { data, loading, refetch } = useGetPointsSubmissionsQuery({
    fetchPolicy: 'network-only',
    variables: {
      propertyID: user?.property_id,
    },
    skip: !user?.id,
  });

  const [declineSubmission, {loading: declining}] = useDeclinePointsSubmissionMutation();
  const [
    revertApprovedSubmission,
    {loading: revertingApproved}
  ] = useRevertApprovedSubmissionMutation();

  const [
    revertDeclinedSubmission,
    {loading: revertingDeclined}
  ] = useRevertApprovedSubmissionMutation();

  const [openedSubmission, setOpenedSubmission] = useState<any>();
  const [cancelSubmission, setCancelSubmission] = useState<any>();
  const [revertSubmission, setRevertSubmission] = useState<any>();

  const handleConfirmDeline = async (data: PointsDecline) => {
    try {
      await declineSubmission({
        variables: {
          pointSubmissionID: cancelSubmission.id,
          declineReason: data.reason
        }
      });
      refetch();
      setCancelSubmission(null);
      resetDeclineForm();
    } catch(err) {
      console.log(err);
      swal({
        title: 'Something went wrong, retry or contact support!',
        icon: 'error',
      });
    }
  };

  const handleCancelDeclineConfirmation = () => {
    setCancelSubmission(null);
    resetDeclineForm();
  };

  const handleConfirmRevert = async () => {
    try {
      if (!revertSubmission?.id) {
        swal({
          title: 'Something went wrong, retry or contact support!',
          icon: 'error',
        });
        return;
      }
      const submit = 
        revertSubmission?.status === 'APPROVED' ? 
          revertApprovedSubmission : 
          revertDeclinedSubmission;
      await submit({
        variables: {
          pointSubmissionID: revertSubmission.id,
        }
      });
      refetch();
      setRevertSubmission(null);
    } catch(err) {
      console.log(err);
      swal({
        title: 'Something went wrong, retry or contact support!',
        icon: 'error',
      });
    }
  };

  const handleCancelRevertConfirmation = () => {
    setRevertSubmission(null);
  };

  useEffect(() => {
    if (openedSubmission) {
      setValue('approvedPoints', openedSubmission?.amount);
    }
  }, [openedSubmission, setValue]);

  const status = ['PENDING', 'APPROVED'];

  data?.propertyPointsSubmissions?.sort((a: any, b: any) => {
    return status.indexOf(a?.status) - status.indexOf(b?.status);
  });

  const [openPoint, setOpenPoint] = useState(false);

  const handleClickOpenPointDialog = (openedSubmission: any) => {
    setOpenedSubmission(openedSubmission);
    setOpenPoint(true);
  };

  const handleClosePointDialog = () => {
    setOpenPoint(false);
  };

  //approved Points submission
  const onSubmit = async (data: PointsApproved) => {
    try {
      await approvePointsSubmission({
        variables: {
          pointSubmissionID: openedSubmission?.id,
          approvedPoints: Math.round(data?.approvedPoints),
        },
      });
      setOpenPoint(false);
      swal({
        title: 'Amount Approved',
        icon: 'success',
      });
      refetch();
    } catch (err) {
      console.log(err);
      swal({
        title: 'Something went wrong, retry or contact support!',
        icon: 'error',
      });
    }
  };

  const [usersMap, setUserMap] = useState({});
  useMemo(
    ()=> {
      const users = _.keyBy(
        usersData?.getPropertyUsers,
        (user: any) => user.id,
      );
      setUserMap(users);
    }, 
    [usersData]);

  return {
    loading: 
      loadingPointsApproved || 
      loading || 
      loadingUsers || 
      declining || 
      revertingApproved || 
      revertingDeclined,
    pointSubmissionsList: data?.propertyPointsSubmissions,
    handleClickOpenPointDialog,
    handleClosePointDialog,
    openPoint,
    onSubmit,
    register,
    handleSubmit,
    errors,
    usersMap: usersMap as {},
    refetch,
    openedSubmission,
    cancelSubmission,
    handleConfirmDeline,
    handleCancelDeclineConfirmation,
    setCancelSubmission,
    declineRegister,
    handleDeclineSubmit,
    revertSubmission,
    setRevertSubmission,
    handleConfirmRevert,
    handleCancelRevertConfirmation
  };
};

export default usePointsSubmission;
