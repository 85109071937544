import { useForm } from 'react-hook-form';
import {
  useGetDepartmentsQuery,
  useGetPropertyUsersQuery,
  useUpsertDepartmentMutation,
} from '../../generated/graphql';
import { openSnackbar } from '../../components/Notifier';
import { NotifierType } from '../../variables/types';
import { useEffect, useState } from 'react';
import { useAuthProvider } from '../../core/authContext';
import _ from 'lodash';

interface DepartmentForm {
  name: string;
}
const useDepartmentsHook = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<DepartmentForm>({
    defaultValues: {
      name: '',
    },
  });

  const { getUser } = useAuthProvider();
  const user = getUser();

  const { data: usersData, loading: loadingUsers } = useGetPropertyUsersQuery({
    fetchPolicy: 'network-only',
    variables: {
      propertyID: user?.property_id,
    },
    skip: !user?.property_id,
  });

  const {
    data,
    loading: loadingDepartments,
    refetch,
  } = useGetDepartmentsQuery({
    fetchPolicy: 'network-only',
    variables: {
      propertyID: user?.property_id,
    },
    skip: !user?.property_id,
  });

  const [openDepartmentForm, setOpenDepartmentForm] = useState(false);
  const [editDepartmentID, setEditDepartmentID] = useState('');

  useEffect(() => {
    if (editDepartmentID) {
      const departmentName = data?.propertyDepartments?.find(
        (dept) => dept?.id === editDepartmentID,
      )?.name;
      setValue('name', departmentName || '');
    }
  }, [editDepartmentID, setValue, data]);

  const [upsertDepartment, { loading: loadingUpsertDepartment }] =
    useUpsertDepartmentMutation();

  const onSubmit = async (data: DepartmentForm) => {
    try {
      await upsertDepartment({
        variables: {
          input: {
            id: editDepartmentID || '',
            name: data.name,
            property_id: user?.property_id || '',
          },
        },
      });
      setOpenDepartmentForm(false);
      setEditDepartmentID('');
      refetch();
      openSnackbar(
        {
          message: editDepartmentID
            ? 'Updated Successfully'
            : 'Created Successfully',
        },
        NotifierType.Success,
      );
    } catch (e: any) {
      console.log(e);
      openSnackbar({ message: e?.toString() || '' }, NotifierType.Error);
    }
  };

  return {
    loading: loadingUpsertDepartment || loadingDepartments || loadingUsers,
    handleSubmit,
    control,
    errors,
    onSubmit,
    refetch,
    departments: data?.propertyDepartments || [],
    openDepartmentForm,
    setOpenDepartmentForm,
    setEditDepartmentID,
    editDepartmentID,
    usersMap: _.keyBy(usersData?.getPropertyUsers || [], 'id'),
    usersByDepartment: _.groupBy(usersData?.getPropertyUsers || [], 'department_id'),
  };
};

export default useDepartmentsHook;
