import { useEffect, useState } from 'react';
import { Hub } from 'aws-amplify';

import { AUTH_KEY } from '../variables/constant';
import client from './apolloClient';
import meQuery from '../utils/meQuery';
import { useAuthProvider } from './authContext';
import { openSnackbar } from '../components/Notifier';
import getErrorMessage from '../utils/getErrorMessage';
import { NotifierType } from '../variables/types';

const useUserInfo = () => {
  const { getUser, setUser } = useAuthProvider();
  const [user, setNewUser] = useState(getUser());
  const [isLoadingUserInfo, setLoadingUserInfo] = useState(false);

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        setLoadingUserInfo(true);
        const { data: userInfo } = await client.query({
          query: meQuery,
          fetchPolicy: 'network-only',
        });
        const userDetails = userInfo?.me;
        setUser(userDetails);
        setNewUser(userDetails);
      } catch (error) {
        openSnackbar({ message: getErrorMessage(error) }, NotifierType.Error);
      }
      setLoadingUserInfo(false);
    };

    const onAuthChange = async ({ payload: { event } }: any) => {
      switch (event) {
        case 'signIn':
          await getUserInfo();
          break;
        case 'signOut':
          setUser(null);
          setNewUser(null);
          break;
      }
    };

    Hub.listen(AUTH_KEY, onAuthChange);
    return () => {
      Hub.remove(AUTH_KEY, onAuthChange);
    };
  }, [setUser]);
  return [isLoadingUserInfo, user];
};

export default useUserInfo;
