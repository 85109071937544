import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { IconButton, Stack } from '@mui/material';
import React from 'react';

interface props {
    image_url: any;
    handleClickOpen: Function;
}
const DocumentList = ({image_url, handleClickOpen}: props) => {
  return (
    !Array.isArray(image_url) ? 
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={()=>handleClickOpen(image_url)}
      >
          <ImageIcon />
      </IconButton>
      :
      <Stack direction="row">
        {
          image_url.map((url: string)=>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={()=>handleClickOpen(url)}
              key={url}
            >
              { url.endsWith(".pdf") ? <PictureAsPdfIcon /> : <ImageIcon /> }
            </IconButton>
          )
        }
    </Stack>);
};

export default DocumentList;