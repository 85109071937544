import React, { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
  Menu,
} from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import { Auth, Cache } from 'aws-amplify';
import { useAuthProvider } from '../../core/authContext';

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    linkTo: '/',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    linkTo: '/profile',
  },
];

// ----------------------------------------------------------------------

const AccountPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { getUser } = useAuthProvider();
  const user = getUser();

  const Logouts = () => {
    Cache.removeItem('session');
    Cache.removeItem('user');
    localStorage.removeItem('token');
    Auth.signOut();
    navigate('/login', { replace: true });
    window.location.reload();
  };
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <Avatar src="assets/avatar.jpg" alt="photoURL" />
      </IconButton>

      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            <b className="text-muted">
              {user ? user.first_name + ' ' + user.last_name : "Loading"}
            </b>
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user ? user.email : "Loading"}
          </Typography>
        </Box>

        <Divider />
        <Box sx={{ my: 1.5, px: 2 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              className="accountpopover-link"
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: 'body2', py: 1, fontWeight: '700' }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Box>
        <Divider />

        <Box sx={{ p: 1, pt: 1 }}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={Logouts}
          >
            <Logout fontSize="small" sx={{ marginRight: '15px' }} /> Logout
          </Button>
        </Box>
      </Menu>
    </>
  );
};
export default AccountPopover;
