import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

const Loader = () => {
  return (
    <Backdrop
      open={true}
      sx={{ color: 'primary', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default Loader;
