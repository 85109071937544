import { GraphQLError } from 'graphql';

const getErrorMessage = (err: any) => {
  if (typeof err === 'string') {
    return err;
  }
  if (err.networkError) {
    return 'Network Error';
  }
  if (err.graphQLErrors) {
    return err.graphQLErrors
      .map((gErr: GraphQLError) => gErr.message)
      .join(', ');
  }
  return err.message;
};

export default getErrorMessage;