import {useForm} from "react-hook-form";
import {openSnackbar} from "../Notifier";
import {NotifierType} from "../../variables/types";
import {useAuthorizeNewUserMutation} from "../../generated/graphql";

export interface AuthorizeForm {
  id?: string;
  user_id: string;
  is_department_head: boolean;
  reports_to: string;
  department_id: string;
  is_manager: boolean;
  is_supervisor: boolean;
  is_admin: boolean;
}

interface props {
  defaultUser: AuthorizeForm,
  onClose: Function,
}
const useUserForm = ({defaultUser, onClose}: props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useForm<AuthorizeForm>({
    defaultValues: {
      user_id: defaultUser.user_id || defaultUser.id,
      is_manager: defaultUser.is_manager || false,
      is_supervisor: defaultUser.is_supervisor || false,
      is_admin: defaultUser.is_admin || false,
      is_department_head: defaultUser.is_department_head || false,
      reports_to: defaultUser.reports_to || "",
      department_id: defaultUser.department_id || "",
    }
  });

  const [authorizeNewUser, { loading: loadingAuthorizeNewUser }] =
    useAuthorizeNewUserMutation();

  const onSubmit = async (data: AuthorizeForm) => {
    console.log(data);
    try {
      await authorizeNewUser({
        variables: {
          authorizeUserInput: {
            user_id: data.user_id,
            is_manager: data?.is_manager,
            is_supervisor: data?.is_supervisor,
            is_admin: data?.is_admin,
            reports_to: data?.reports_to,
            department_id: data?.department_id,
            is_department_head: data?.is_department_head,
          },
        },
      });
      onClose();
      openSnackbar({ message: 'Updated Successfully' }, NotifierType.Success);
    } catch (err: any) {
      console.log(err);
      openSnackbar({ message: err?.toString() || '' }, NotifierType.Error);
    }
  };

  const selectedDeptID = watch('department_id');
  const selectedUserIsDeptHead = watch('is_department_head');
  const selectedUserIsAdmin = watch('is_admin');
  const selectedUserIsSupervisor = watch('is_supervisor');
  const selectedUserIsManager = watch('is_manager');
  const selectedReportsTo = watch('reports_to');
  return {
    loading: loadingAuthorizeNewUser,
    register,
    handleSubmit,
    errors,
    onSubmit,
    selectedDeptID,
    selectedUserIsDeptHead,
    getValues,
    selectedUserIsAdmin,
    selectedUserIsSupervisor,
    selectedUserIsManager,
    setValue,
    selectedReportsTo,
  };
};

export default useUserForm;
