import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useAuthProvider} from '../../core/authContext';
import swal from 'sweetalert';

import {
  Challenge,
  ChallengeRewardType,
  ChallengeStatus,
  useAddChallengeMutation,
  useAddSubChallengeMutation,
  useDeleteChallengeMutation,
  useDeleteSubChallengeMutation,
  useGetChallengesQuery,
  useGetPropertyDepartmentsQuery,
  useUpdateChallengeStatusMutation,
} from '../../generated/graphql';
import _ from 'lodash';
import useUpload from "../../utils/upload";

export interface ChallengeForm {
  id: string;
  title: string;
  department_id: string;
  description: string;
  points: number;
  reward_type: ChallengeRewardType;
  max_reward_points: number;
  doc_urls: string[];
}
interface SubChallengeForm {
  id: string;
  challenge_id: string;
  title: string;
  department_id: string;
  description: string;
  points: number;
  reward_type: ChallengeRewardType;
  max_reward_points: number;
}
const useChallenge = () => {
  const [subChallengeId, setSubChallengeId] = React.useState();
  const [challengeName, setChallengeName] = React.useState('');
  const [openChallenge, setOpenChallenge] = React.useState(false);
  const [openSubChallenge, setOpenSubChallenge] = React.useState(false);
  const [departmentMap, setDepartmentMap] = React.useState({});
  const [uploadingFile, setUploadingFile] = React.useState(false);
  const {uploadFile} = useUpload();

  const [upsertChallenge, { loading: loadingUpsertChallenge }] =
    useAddChallengeMutation();

  const [upsertSubChallenge, { loading: loadingUpsertSubChallenge }] =
    useAddSubChallengeMutation();

  const [deleteChallenge, { loading: loadingDeletChallenge }] =
    useDeleteChallengeMutation();

  const [deleteSubChallenge, { loading: loadingDeletSubChallenge }] =
    useDeleteSubChallengeMutation();

  const [updateChallengeStatus, { loading: loadingUpdateStatus }] =
    useUpdateChallengeStatusMutation();

  const {
    register: registerChallenge,
    handleSubmit: handleSubmitChallenge,
    reset,
    formState: { errors: errorsChallenge },
    watch: challengeFormWatch,
    getValues,
    setValue
  } = useForm<ChallengeForm>();

  const {
    register: registerSubChallenge,
    handleSubmit: handleSubmitSubChallenge,
    formState: { errors: errorsSubChallenge },
    watch: subChallengeFormWatch,
  } = useForm<SubChallengeForm>();

  // Challenge Handle
  const handleClickChallengeOpen = () => {
    setOpenChallenge(true);
  };
  const handleChallengeClose = () => {
    setOpenChallenge(false);
    setToBeEditedChallenge(null);
  };

  // Sub Challenge Handle
  const handleClickSubChallengeOpen = (id: any, challengeName: string) => {
    setSubChallengeId(id);
    setChallengeName(challengeName);
    setOpenSubChallenge(true);
  };

  const handleSubChallengeClose = () => {
    setOpenSubChallenge(false);
  };

  const { getUser } = useAuthProvider();
  const user = getUser();

  // Fetch Challenges
  const { data, loading, refetch } = useGetChallengesQuery({
    fetchPolicy: 'network-only',
    variables: {
      propertyID: user?.property_id,
    },
    skip: !user?.property_id,
  });

  //Fetch Departments
  const { data: depData, loading: depLoading } = useGetPropertyDepartmentsQuery(
    {
      fetchPolicy: 'network-only',
      variables: {
        propertyID: user?.property_id,
      },
      skip: !user?.property_id,
    },
  );
  useEffect(() => {
    if (depData?.propertyDepartments) {
      setDepartmentMap(
        _.keyBy(
          depData?.propertyDepartments,
          (department: any) => department.id,
        ),
      );
    }
  }, [depData]);

  // Add Challenge graphql
  const onSubmit = async (data: ChallengeForm) => {
    try {
      await upsertChallenge({
        variables: {
          challengeInput: {
            id: data?.id || null,
            property_id: user?.property_id,
            department_id: data?.department_id,
            name: data?.title || '',
            description: data?.description,
            points: data?.points || 0,
            reward_type: data?.reward_type || ChallengeRewardType.Direct,
            max_reward_points: data?.max_reward_points || 0,
            doc_urls: data?.doc_urls || [],
          },
        },
      });
      setOpenChallenge(false);
      await refetch();
      // alert('Challenge Added Successfully');
      await swal({
        title: 'Good job!',
        text: 'Challenge Added Successfully',
        icon: 'success',
      });
    } catch (err) {
      console.log(err);
    }
    reset();
  };

  // Add  Sub Challenge graphql
  const onSubmitSubChallenge = async (data: SubChallengeForm) => {
    try {
      await upsertSubChallenge({
        variables: {
          subChallengeInput: {
            id: data?.id || null,
            challenge_id: subChallengeId || '',
            name: data?.title || '',
            description: data?.description,
            points: data?.points || 0,
            reward_type: data?.reward_type || ChallengeRewardType.Direct,
            max_reward_points: data?.max_reward_points || 0,
          },
        },
      });
      setOpenSubChallenge(false);
      swal({
        title: 'Good job!',
        text: 'Sub Challenge Added',
        icon: 'success',
      });
      refetch();
    } catch (err) {
      console.log(err);
    }
    reset();
  };

  // Delete Challenge
  const handleClickChallengeDelete = async (challenge_id: any) => {
    try {
      await deleteChallenge({
        variables: {
          challengeID: challenge_id,
        },
      });
      swal({
        text: 'Challenge Deleted',
        icon: 'success',
      });
      await refetch();
    } catch (err) {
      console.log(err);
    }
  };
  // Delete Sub Challenge
  const handleClickSubChallengeDelete = async (sub_id: any) => {
    try {
      await deleteSubChallenge({
        variables: {
          subChallengeID: sub_id,
        },
      });
      swal({
        text: 'Sub Challenge Deleted',
        icon: 'success',
      });
      refetch();
    } catch (err) {
      console.log(err);
    }
  };

  //Update Challenge Status
  const handleUpdateStatus = async (challenge_id: any) => {
    try {
      await updateChallengeStatus({
        variables: {
          challengeID: challenge_id,
          challengeStatus: ChallengeStatus.Active,
        },
      });
      swal({
        title: 'Good job!',
        text: 'Challenge Activated',
        icon: 'success',
      });
      refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null) {
      setUploadingFile(true);
      const uploadedURL = await uploadFile(e);
      if (uploadedURL) {
        let files = getValues('doc_urls');
        if (files && files.length > 0) {
          files = [...files, uploadedURL];
        } else {
          files = [uploadedURL];
        }
        setValue('doc_urls', files);
      }
      setUploadingFile(false);
    }
  };

  const challengeTermsAndConditions = challengeFormWatch("doc_urls");

  const removeTermsFile = (fileName: string) => {
    const filteredList = challengeTermsAndConditions.filter(
      (termFile: string) => termFile !== fileName
    );
    setValue('doc_urls', filteredList);
  };

  const [
    toBeEditedChallenge,
    setToBeEditedChallenge
  ] = useState<Challenge | null>(null);
  const handleClickChallengeEdit = async (challenge_id: string) => {
    const challenge = await data?.challenges?.find(c => c?.id === challenge_id);
    if (challenge) {
      await setToBeEditedChallenge(challenge);
      await setOpenChallenge(true);
    }
  };

  useEffect(()=> {
    if (toBeEditedChallenge) {
      let challenge: ChallengeForm = {
        id: toBeEditedChallenge.id,
        title: toBeEditedChallenge.name || "",
        department_id: toBeEditedChallenge.department_id || "",
        description: toBeEditedChallenge.description || "",
        points: toBeEditedChallenge.points || 0.0,
        reward_type:
          toBeEditedChallenge.reward_type ||
          ChallengeRewardType.Percent,
        max_reward_points: toBeEditedChallenge.max_reward_points || 0.0,
        doc_urls: toBeEditedChallenge?.doc_urls?.map(url => url || "") || [],
      };
      reset(challenge);
    }
  }, [toBeEditedChallenge, reset]);

  const challengeRewardType = challengeFormWatch("reward_type");
  const subChallengeRewardType = subChallengeFormWatch("reward_type");

  return {
    loading:
      loading ||
      depLoading ||
      loadingUpdateStatus ||
      loadingDeletSubChallenge ||
      loadingDeletChallenge ||
      loadingUpsertSubChallenge ||
      loadingUpsertChallenge,
    dataRows: data?.challenges,
    deptData: depData?.propertyDepartments,
    departmentMap,
    handleChallengeClose,
    onSubmit,
    registerChallenge,
    handleClickChallengeOpen,
    handleSubmitChallenge,
    openChallenge,
    registerSubChallenge,
    handleSubmitSubChallenge,
    errorsChallenge,
    errorsSubChallenge,
    onSubmitSubChallenge,
    handleClickSubChallengeOpen,
    handleSubChallengeClose,
    openSubChallenge,
    challengeName,
    handleClickChallengeDelete,
    handleClickSubChallengeDelete,
    handleUpdateStatus,
    challengeRewardType,
    subChallengeRewardType,
    handleUpload,
    challengeTermsAndConditions,
    removeTermsFile,
    toBeEditedChallenge,
    handleClickChallengeEdit,
    loader: uploadingFile
  };
};

export default useChallenge;
